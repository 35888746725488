import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const items = [
    "https://ik.imagekit.io/zjvk6l5gp/962shots_so.png?updatedAt=1709045165936",
    ""
]

export default function Privacyhero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
      
        backgroundImage: "linear-gradient(180deg, rgba(125, 93, 246, 0.1), #fff)",
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
       
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          width: "100%"
        }}
      >

         <Typography
              component="span"
              variant="h1"
              sx={{
               
                color: "#20136E",
                fontSize: {xs: 30, sm: 42}
              }}
            >
              Privacy policy
            </Typography>
       
         


    { !isMobile ? (
        <>
         <Grid container spacing={6} sx={{mt: 18}}>
   
   <Grid  xs={12} md={6} display  
           sx={{

           }}
           >
             <div>
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:35, ml: 23, fontWeight: 900 }}>
             Almond Privacy Policy
                 
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:21, ml: 15, fontWeight: 500, mt:2, textAlign: "center" }}>
               This privacy policy (“Policy”) applies to the website(s) and mobile application(s) (hereinafter referred to as, the "Sites") provided by Almond a digital product of Transatlantic Trusted Partners Limited (“Transatlantic Trust”, “we”, “us”, “our”) , and other products/services of Transatlantic Trust Limited and subsidiaries. 
               This Policy discloses our data protection practices on our Sites, products and subscriber-based services (“Services”), inclusive of the type of personal data that we collect, our method of collection of personal data, use of personal data and procedures for sharing personal data with third parties.
               </Typography>
              
             </div>
             
           
            
           </Grid> 

<Grid
       item
       xs={12}
       md={6}
       sx={{ display: "flex", width: '100%' }}
     >

         
<Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         ml: 10,
         mt:8
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '70%',
             height: '70%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>


      

     </Grid>
    
   </Grid>
      

  
   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 10, ml: -87 }}>
   More on our privacy policy
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   The Sites covered by this Policy include our existing websites, mobile applications and all other additional websites and mobile applications produced and managed by Transatlantic Trust. Details of existing Sites include the following: 
<br/>•	Almond - www.almondnigeria.com;<br/>
We value the trust you place in us and understand that your privacy is of utmost importance to you. In light of this, we make use of the highest standards to ensure secure transactions and the privacy of customer information.
We are committed to protecting your personal data (i.e. any information you provide to us through which you can be identified) in accordance with the provisions of the Nigeria Data Protection Act 2023 and other applicable data protection laws (“Data Protection Laws”).
By visiting the Sites (including all websites and mobile applications which may be added or removed from time to time) you agree to the terms and conditions of this Privacy Policy. If you do not want your personal data processed in accordance with this Policy, please do not use or access the Sites or the Services. 
We reserve the right, at our sole discretion, to alter and update this Policy from time to time. We therefore invite you to review the current version of this Policy each time you return to the Sites.

                 
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -104 }}>
               ELIGIBILITY
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   The Sites and Services are intended solely for persons who, if they are natural persons, are eighteen (18) years of age or older, 
   and any registration by, use of or access to the Sites and Services by any natural person under eighteen (18) is unauthorised, unlicensed and in violation of this Policy. 
                 
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -109 }}>
               CONSENT
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   By using the Sites, Services and by providing your personal data, you consent to the collection and use of the information you disclose to us in accordance with this Policy, including but not limited to your consent for sharing your personal data in line with the terms contained in this Policy. 
   If we decide to change this Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it and under what circumstances we disclose it. If you do not agree to give consent to the use of personal data as described in this Policy, please do not use or access the Sites or Services.
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -13 }}>
               LAWFUL BASIS FOR COLLECTING AND PROCESSING PERSONAL DATA
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   In accordance with the provisions of Data Protection Laws, prior to the processing of personal data there must be in existence a legal basis for such processing. In compliance with the provisions of Data Protection Laws, we process your personal data in line with the following legal basis: 
   <br/>•	Consent: where you have consented to our processing of your personal data for one or more specific reasons. Such consent is given by you through your continuous use of the Services and the Sites.<br/>
   <br/>•	Performance of a contract: in order to perform a contract we have with you or a contract to which you are a party to and in order to take necessary steps at your request prior to entering into such a contract.<br/>
   <br/>•	Legal obligation: where processing of personal data is required by law. We are required by law to retain certain account opening information and personal data of our customers beyond the date such customers cease to carry on business with us.<br/>
   <br/>•	Legitimate interest: in order to protect the vital interests of other data subjects, and in order to carry out the purposes of our business. In addition to this, we have a legitimate interest to prevent fraud, money laundering and to verify identity of data subjects, in order to protect our customers and business, to understand how people interact with our Sites, to provide communication which we think will be of interest to you and to determine the effectiveness of promotional campaigns and advertising.<br/>
   <br/>•	Public interest: such processing is necessary for the performance of a task carried out in the interest of the public on in exercise of an official public mandate vested on us.<br/>

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -52 }}>
               INFORMATION WE MAY COLLECT FROM YOU
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   When you use the Sites or Services, we collect and store your personal data which is provided by you from time to time. 
Personal data/ information in this context shall include all data such as: any means of information relating to an identified or identifiable natural person who can be identified by:
<br/>•	A name;<br/>
<br/>•	An identification number;<br/>
<br/>•	Location data, an online identifier;<br/>
<br/>•	Address, a photo, an email address;<br/>
<br/>•	Facial recognition data;<br/>
<br/>•	Bank details;<br/>
<br/>•	Other unique identifiers such as but not limited to MAC address, IP address, IMEI number, IMSI number, SIM.<br/>
For the purpose of accessing our Services, the personal data we may collect include: your full legal names, marital status, title, date of birth, gender, photo, facial recognition data, business name, email address, mailing address, telephone number, bank account number, payment card details, bank verification number, national identification number, international passport number, means of identification, guarantors contact details, bank statements, usernames, password, your preferences, interests, feedback and survey responses, preference in receiving marketing information from us and our third parties and your communication preferences, etc. <br/>
Our primary goal in collecting the above stated personal data is to provide you with a safe, efficient, smooth and customised experience. This allows us to provide Services and features that most likely meet your needs, and to customise the Sites to make your experience safer and easier. 

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -70 }}>
               HOW WE COLLECT INFORMATION
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We collect information you provide directly to us, for example, we collect information when you register or log on to the Sites, create an account, subscribe to a Service, participate in any interactive features on our Services, fill out a form, take part in surveys, post on our message boards, upload any documentation, request customer support, make an enquiry, communicate with us by email, phone or post, interact with us on social media, etc.
We will also collect your information where you partially complete and/or abandon any information inputted in the Sites and may use this information to contact you to remind you to complete any outstanding information.
Every computer connected to the internet is given a domain name and a set of numbers that serve as that computer’s internet protocol “IP address”. When you use the Sites, our web servers automatically recognize your domain name and IP address. The domain name and IP address reveals nothing personal about you other than the IP address from which you have accessed the Sites. We are able to see information relating to your browsing patterns and technical data about the equipment you use to access the website through the use of cookies, server logs and other similar technologies. You can select your preference from the cookies settings on any of our websites. 
We may also collect technical data from third parties/ public sources such as analytics providers, identity verification providers, advertising networks, search information providers. We may obtain contact, financial and transaction data from providers of technical, payment, credit referencing and delivery services based both inside and outside Nigeria. We utilise third-party service providers to secure information related to financial crime, fraud, sanctions and politically exposed persons.
We do not own personal data provided and will only store such data for a period reasonably needed and we will do our best to ensure that such personal data is secured against all foreseeable hazards and breaches such as theft, cyber-attack, viral attack, unauthorised dissemination, manipulation of any kind, damage by rain, fire or exposure to other natural elements. 
We will not sell, share, transfer or rent out any personal information to others in ways different from what is disclosed in this Policy, and our terms and conditions of use. We may share generic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers.
Providing us with information about others
If you provide us with personal data about someone else, you are responsible for ensuring that you have provided the required notices and have obtained the individual’s explicit consent to provide us with the personal data and that you explain to them how we collect, use, disclose and retain their personal data or direct them to read our Policy. 

               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -39 }}>
               INFORMATION WE COLLECT FROM OTHER SOURCES
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   In order to provide you with access to the Services, or to provide you with better service in general, we may combine information obtained from other sources (for example, a third-party developer whose application you have authorised) and combine that with information we collect through the Sites.
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -65 }}>
               HOW WE USE YOUR PERSONAL DATA
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   The purpose of collecting your personal data is to give you an efficient, enjoyable, secure and seamless customer experience. 
We may use your personal data for the following purposes:
<br/>•	To provide the requested Services and support to you;<br/>
<br/>•	To process transactions and send notices about your transactions to requisite parties;<br/>
<br/>•	To verify your identity; <br/>
<br/>•	To resolve disputes and troubleshoot problems;<br/>
<br/>•	To manage risk, detect, prevent, and/or remediate fraud or other potentially prohibited or illegal activities;<br/>
<br/>•	To detect, prevent or remediate violations of policies or applicable user agreements;<br/>
<br/>•	To improve our services by implementing aggregate customer preferences;<br/>
<br/>•	To manage and protect our information technology infrastructure;<br/>
<br/>•	To contact you at any time through your provided telephone number, email address or other contact details; <br/>
<br/>•	To notify you about activities on your account, troubleshoot problems with your account and collect fees or monies owed; <br/>
<br/>•	To monitor traffic patterns and usage of the Sites to help to improve the Sites design and layout;<br/>
<br/>•	To record and store communications made via phone, skype or the website chat function;<br/>
<br/>•	To personalise your experience on our Sites or communications/advertising;<br/>
<br/>•	To provide customer service, including to respond to your enquiries and fulfil any of your requests for information;<br/>
<br/>•	To send you important information regarding the services and/or other technical notices, updates, security alerts, support and administrative messages;<br/>
<br/>•	To poll your opinions through surveys or questionnaires<br/>
<br/>•	As Almond believes to be necessary or appropriate<br/>
<br/>•	To comply with a legal obligation. This applies where the processing is necessary for Almond to comply with the law;<br/>
<br/>•	To enforce or apply this Policy<br/>
<br/>•	To protect Almond’s legitimate interests, privacy, property or safety, and/or those of a third party as long as your rights do not override those interests.<br/>
We may monitor and record our communications with you, including e-mails and phone conversations for training, quality assurance purposes, and to meet our legal and regulatory obligations in general.
<br/><strong>Fraud prevention</strong><br/>
We may process your personal data on the basis that we have a legitimate interest to prevent fraud and money laundering, and to verify your identity, in order to protect our business and to comply with laws that apply to us. Such processing is also a contractual requirement of the services you have requested.
We may carry out fraud prevention checks using a fraud prevention database. If false or inaccurate information is provided, and/or fraud is identified, details will be passed to the Central Bank of Nigeria and the Economic and Financial Crimes Commission. Additionally, law enforcement agencies may access and use this information.
<br/><strong>Data analytics and bench-marking</strong><br/>
We may use information generated and stored during your use of our Services for our legitimate activities to enable us to give you the best service and/or solutions and the best experience. These purposes include to:
<br/>•	Deliver advertising or information to you which may be useful to you, based on your use of preferences<br/>
<br/>•	Carry out research and development to improve our Services;<br/>
<br/>•	Develop and provide new and existing functionality and Services (including statistical analysis, benchmarking and forecasting Services)<br/>
<br/>•	Provide you with location-based Services (for example location relevant content) where we collect geo-location data to provide a relevant experience.<br/>
Whenever we use your information for our legitimate interests, we will ensure that your information is processed on a pseudonymised basis and displayed at aggregated levels, which will not be linked back to you or to any living individual.
You have the right to object to processing based on our legitimate activities but if you object, this may affect our ability to provide certain Services and/or solutions for your benefit.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -70 }}>
               YOUR RIGHTS AS A DATA SUBJECT
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   Your personal data is protected by legal rights enshrined in Data Protection Laws. These rights include the following:
   <br/>•	The right to be told how we use your personal data and obtain access to your personal data;<br/>
   <br/>•	The right to have your personal data rectified or erased or place restrictions on processing your personal data;<br/>
   <br/>•	The right to object to the processing of your personal data e.g. where the processing is based on our legitimate interests. Please note that this may prevent us from continuing to provide Services to you;<br/>
   <br/>•	The right to have any information you provided to us on an automated basis returned to you in a structured, commonly used and machine-readable format, or sent directly to another organisation, where technically feasible (“data portability”);<br/>
   <br/>•	Where the processing of your personal data is based on your consent, the right to withdraw that consent subject to legal or contractual restrictions; <br/>
   <br/>•	The right to object to any decisions based on the automated processing of your personal data, including profiling; <br/>
   <br/>•	The right to lodge a complaint with the supervisory authority responsible for data protection matters.<br/>
Please note that if you request for a copy of your personal data, you may be required to pay a fee.
If you would like to exercise any of the above stated rights, please follow the following procedures:
<br/>•	Put your request in writing and send it to us through your usual registered channel (e.g. by registered email).<br/>
<br/>•   Specify the right you wish to exercise.<br/>
<br/>•	You can also access the Data Subject Access Request (DSAR) portal on our website.<br/>
For more information or to exercise your data protection rights please, please contact our Data Protection Officer at <strong>support@almondnigeria.com.</strong>
<br/>We will endeavour to process all subject access requests within thirty (30) days and if any further extension is required, we will communicate same through existing consented channels – at no cost. However, please note that you may continue to receive existing communications for a transitional period whilst we update your preferences.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -80 }}>
               RETENTION OF YOUR DATA
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We will not retain your personal data for longer than is necessary for the purposes for which such personal data is processed. This means that your personal data will only be retained for as long as it is still required to provide you with the Services or is necessary for legal reasons. When calculating the appropriate retention period of your personal data we consider the nature and sensitivity of the personal data, the purposes for which we are processing such personal data, and any applicable statutory/regulatory retention periods. Using these criteria, we regularly review the personal data that we hold and the purposes for which such is held and processed. Our Payment Card Industry Data Security Standard (“PCIDSS”) obligation means that we are obliged to retain personal data for a minimum of ten (10) years from the end date of our business relationship with you. 
When we determine that personal data can no longer be retained (or where you request that we delete your personal data in accordance with your rights contained in Data Protection Laws) we ensure that such personal data is securely deleted, anonymized or destroyed. However, please note that, in some circumstances we may decide to retain your personal data as may be reasonably necessary in accordance with the provisions of Data Protection Laws. In such circumstances, we will anonymize your personal data before retaining same.
<br/>Please see details of our data retention and disposal process below: <br/>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type of data</TableCell>
            <TableCell>Retention Period</TableCell>
            <TableCell>Disposal Process</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Electronic storage on database</TableCell>
            <TableCell>10 years (regulatory reasons)</TableCell>
            <TableCell>Programmatic (automatic) process to remove, at least on a quarterly basis, personal data that exceeds business retention requirements/reviews conducted at least on a quarterly basis</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hardcopy data (receipts/faxes)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Cross-cut shredded/incinerated, pulped</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hard drives (back-up)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Secure wipe program/degauss</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tape Media (back-up)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Physically destroy</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>System and network logs</TableCell>
            <TableCell>.</TableCell>
            <TableCell>year</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
On at least a quarterly basis, we systematically remove and destroy all cardholder data that has exceeded its retention period, and review and ensure the remaining stored cardholder data remains within the formal retention requirements. 
<br/>Wherever the primary account number (“PAN”) is stored, whether electronically or on paper, it is masked. The first six and last four digits are the maximum number of digits that may be displayed. Certain members of the operations and Service delivery units have a legitimate business need when dealing with customer/cardholder enquiries to access the PAN. Wherever the PAN is stored (including in logs, removable media, etc.), it is made unreadable by means of one-way hashes. Cardholder data is never stored on removable media and when removable physical storage media (including documents, faxes, and electronic media) are no longer required (i.e. they have passed their retention periods), they are destroyed. <br/>
               </Typography>


               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -80 }}>
               ACCURACY OF YOUR DATA
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   It is important that the personal data Almond holds about you is accurate and current. Please keep Almond informed if any aspect of your personal data changes at any time during your relationship with us. On our customer facing products, you can easily update your personal data yourself or alternatively contact our Data Protection Officer via <strong> support@almondnigeria.com</strong> when you want to exercise your right of rectification.
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -83 }}>
               SECURITY OF YOUR DATA
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   In order to protect your personal data, we have put in place appropriate organisational and technical security measures. These measures include storing data on a dedicated and secure server with at least 256-bit encryption, restricting access to your personal data to certain employees, ensuring that our internal information technology systems are suitably secure, and implementing procedures to deal with any suspected data breach.<br/>
   <br/>In the unlikely event of a data breach, Almond will take steps to mitigate any loss or destruction of data and, if appropriate, will notify you and any applicable authority of such a breach.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -73 }}>
               DATA TRANSFERS AND SHARING
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   Due to the fact that we operate in a regulated environment, we cannot ensure that all your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Policy. By way of example (without limiting the foregoing), we may be forced to disclose information to the government, regulatory bodies, law enforcement agencies, and third parties for the performance of a task carried out in the interest of the public, for the protection of your vital interest, for the performance of a contract which you are a party to and also where you have expressly given us written consent to disclose same.
We may need to pass your information to third party service providers which maintain, administer or develop the Sites on our behalf and the information will only be provided for such limited purposes and as detailed below. Additionally, we may provide aggregate statistics about our customers, sales, traffic patterns and related website information to reputable third-parties, but these statistics will include no personally identifiable information.
<br/>Almond may transfer your personal data to third parties (“Third Party Providers”) of the following types: <br/>
<br/>•	Companies providing identity or financial validation services; <br/>
<br/>•	Financial product providers; <br/>
<br/>•	Payment services companies acting on your, or our behalf; <br/>
<br/>•	Banks; <br/>
<br/>•	Companies providing analytics services; <br/>
<br/>•	Data, service and software providers; <br/>
<br/>•	Companies collecting and publishing customer reviews; <br/>
<br/>•	Marketing services companies <br/>
<br/>•	Regulatory and law enforcement bodies. <br/>
A few of our identity verification Third Party Providers collect your personal data via our Sites through the use of Apple Inc. 's (“Apple”) TrueDepth Application Programming Interface (“TrueDepth API”). As a result of the integration of our Sites with such Third Party Providers, our Sites make use of automatically collected information using the device camera on your Apple mobile device and the TrueDepth API provided by Apple. 
<br/>The use of your personal data collected as a result of this is to track your facial features, and control the augmented reality (AR) experience. We use ARKit to capture your face 3D spatial orientation and facial expressions. In doing this, we use this data to ensure that the picture (selfie) being taken is of a live user for authentication and fraud reduction purposes. The ARKit information is processed entirely locally and the spatial orientation/facial expression data is not submitted to any third (or first) parties. None of the information collected by the TrueDepth API ever leaves your mobile device nor is it persistently stored on the device. 
We will do our reasonable best to ensure personal data provided by you to us and shared with a Third Party Provider is done in accordance with the provisions of Data Protection Laws. We will also reasonably ensure that such Third Party Providers with whom we share your personal data will ensure the security of the same as provided by this Policy and in accordance with Data Protection Laws.  <br/>

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -95 }}>
               CONFIDENTIALITY
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   You further acknowledge that the Sites may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal data to any third party nor use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed Services.
   <br/>We take all necessary precautions to protect your personal information both online and off-line. It is important for you to protect against unauthorised access to your password, your mobile phone or computer. Be sure to log off from the Sites when you are using a shared phone/computer. We also protect your personal data off-line. Access to your personal data is limited to employees, agents or partners and third parties with whom we are working who we reasonably believe will need that information to provide the Services to you. <br/>

               </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -110 }}>
                COOKIES
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We use data collection devices such as ‘cookies’ on certain pages of the Sites. Cookies are small files stored on your hard drive that assist us in providing Services customised to your requirements and tastes. We also offer certain features that are only available through the use of a ‘cookie’. Cookies can also help us provide information, which is targeted to your interests. Cookies may be used whether you choose to register with Us or not. 
   <br/>We also use cookies to allow you to enter your password less frequently during a session. Most cookies are ‘session cookies’, meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Sites and you may be required to re-enter your password more frequently during a session. A cookie cannot read data off your hard disk or read cookie files created by other sites. Use of a cookie is in no way linked to any personally identifiable information while on the Sites. Once you close your browser, the cookie simply terminates. For instance, by setting a cookie on your browser, you would not have to log in a password more than once, thereby saving time while on the Sites. <br/>
You can choose whether to accept cookies by changing the settings of your browser. You can reset your browser to refuse all cookies or allow your browser to show you when a cookie is being sent. If you reject the cookies on the Sites, you may still be able to use the Sites, but it shall be limited to certain minimal functionality. The only drawback to this is that you may be limited to some areas of Sites or limited to certain functions of the Sites.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -98 }}>
               JOB APPLICANTS
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   If you apply for a job at Almond, you will be asked to submit information to Almond such as your name, contact details, information about your education and work history and any other background information that might be relevant to your application or that you choose to share with us. If you do not provide this information to us, we might not be able to process your application.
   <br/>We will use this information to assess your application and candidacy for the position you have applied for. Without limiting the generality of the foregoing, this may include:<br/>
   <br/>•	Evaluating your skills and experience in relation to the qualification required for the job you have applied for<br/>
   <br/>•	Tracking feedback and interactions, we have with you throughout the recruiting process. We may also use your information when analysing our internal recruitment processes to determine:<br/>
   <br/>•	Which recruitment sources to focus on;<br/>
   <br/>•	How to improve integration and training programs for new hires with different educational backgrounds and work experience<br/>
   <br/>•	How to enhance our interview model to improve hiring quality.<br/>
The information you provide may also be used to communicate with you about Almond’s events and to send you publications that we think may be of interest to you.
<br/>We might share your personal data with other companies in our group as well as with third parties such as recruitment service providers, background check providers and information technology system providers. These Almond group companies and third parties might be located in a different country than your country of residence.<br/>
We will keep your application data for up to one (1) year. If you no longer wish for Almond to process your personal data or if you wish to exercise any of your rights as a data subject, please contact <strong>support@almondnigeria.com</strong>. 

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -104 }}>
               DISCLAIMER
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We will keep your information secure by taking appropriate technical and organisational measures against its unauthorised or unlawful processing and against its accidental loss, destruction or damage. We will do our best to protect your personal data, but we cannot guarantee the security of your personal data which is transmitted to other websites via an internet or similar connection. 
   <br/>If we have given you (or you have chosen) a password to access certain areas of the Sites please keep this password safe, we will not share this password with anyone. <br/>
   As a user of the Services, you understand and agree that you assume all responsibility and risk attached to safeguarding your account with us. You shall at no time whatsoever disclose your password to anyone, nor shall you allow anyone make use of your account.
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -69 }}>
               UPDATES TO THE PRIVACY POLICY
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We are constantly trying to improve our Sites and Services, so we may need to change this Policy from time to time as well. We will alert you of material changes by, for example, placing a notice on our websites and/or by sending you an email (if you have registered your e-mail details with us) when we are required to do so by applicable law. We reserve the right to update this Policy as we deem fit, from time to time, without any intimation to you and your continued use of the Sites will signify your acceptance of any amendment to these terms. 
Our updated terms will also be displayed on our website (www.almondnigeria.com) It is your responsibility to check this Privacy Policy from time to time to verify such updates.
If you believe at any time that we have not handled your personal data in accordance with this Policy, please contact our Data Protection Officer.
We have appointed a Data Protection Officer (DPO) who is responsible for dealing with all such concerns, in addition to overseeing questions relating to this Policy and handling requests in relation to the exercise of your rights. If you have any concerns or questions, please contact the Data Protection Officer using the details set out below.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -109 }}>
               CONTACT
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
Data Protection Officer
   <br/>Almond Nigeria<br/>
   <br/>No.10 Omowumi Street, New Oko-oba, Alimosho Lagos State. <br/>
   <br/><strong>support@almondnigeria.com</strong><br/>

   <br/>If you have any questions, comments and requests regarding your privacy and rights, please let us know how we can help.
Last Updated: 1st March 2024.


               </Typography>
   </>

   ) : 
   
   (
    <>
 
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:25, fontWeight: 900,textAlign: "center", display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5  }}>
              Almond Privacy Policy
                 
               </Typography>
               <Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         mt:1,
         display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
         // backgroundImage: (theme) =>
         //   theme.palette.mode === 'light'
         //     ? items[selectedItemIndex].imageLight
         //     : items[selectedItemIndex].imageDark,
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '30%',
             height: '30%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 500, mt:3, textAlign: "center",display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center', }}>
               This privacy policy (“Policy”) applies to the website(s) and mobile application(s) (hereinafter referred to as, the "Sites") provided by Almond a digital product of Transatlantic Trusted Partners Limited (“Transatlantic Trust”, “we”, “us”, “our”) , and other products/services of Transatlantic Trust Limited and subsidiaries. 
               This Policy discloses our data protection practices on our Sites, products and subscriber-based services (“Services”), inclusive of the type of personal data that we collect, our method of collection of personal data, use of personal data and procedures for sharing personal data with third parties.
               </Typography>
              
               <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 5,
    }}
  >
    More on our privacy policy
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
               //textAlign: "center",
                    mt: 1
                    }}>
                 The Sites covered by this Policy include our existing websites, mobile applications and all other additional websites and mobile applications produced and managed by Transatlantic Trust. Details of existing Sites include the following: 
<br/>•	Almond - www.almondnigeria.com;<br/>
We value the trust you place in us and understand that your privacy is of utmost importance to you. In light of this, we make use of the highest standards to ensure secure transactions and the privacy of customer information.
We are committed to protecting your personal data (i.e. any information you provide to us through which you can be identified) in accordance with the provisions of the Nigeria Data Protection Act 2023 and other applicable data protection laws (“Data Protection Laws”).
By visiting the Sites (including all websites and mobile applications which may be added or removed from time to time) you agree to the terms and conditions of this Privacy Policy. If you do not want your personal data processed in accordance with this Policy, please do not use or access the Sites or the Services. 
We reserve the right, at our sole discretion, to alter and update this Policy from time to time. We therefore invite you to review the current version of this Policy each time you return to the Sites.

                  
                </Typography>


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
   ELIGIBILITY
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
               //textAlign: "center",
                    mt: 1
                    }}>
                  The Sites and Services are intended solely for persons who, if they are natural persons, are eighteen (18) years of age or older, 
   and any registration by, use of or access to the Sites and Services by any natural person under eighteen (18) is unauthorised, unlicensed and in violation of this Policy. 
              
                  
                </Typography>

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
 CONSENT
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
               //textAlign: "center",
                    mt: 1
                    }}>
                  By using the Sites, Services and by providing your personal data, you consent to the collection and use of the information you disclose to us in accordance with this Policy, including but not limited to your consent for sharing your personal data in line with the terms contained in this Policy. 
   If we decide to change this Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it and under what circumstances we disclose it. If you do not agree to give consent to the use of personal data as described in this Policy, please do not use or access the Sites or Services.
             
                  
                </Typography>

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
LAWFUL BASIS FOR COLLECTING AND PROCESSING PERSONAL DATA
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
                    mt: 1
                    }}>
                    In accordance with the provisions of Data Protection Laws, prior to the processing of personal data there must be in existence a legal basis for such processing. In compliance with the provisions of Data Protection Laws, we process your personal data in line with the following legal basis: 
   <br/>•	Consent: where you have consented to our processing of your personal data for one or more specific reasons. Such consent is given by you through your continuous use of the Services and the Sites.<br/>
   <br/>•	Performance of a contract: in order to perform a contract we have with you or a contract to which you are a party to and in order to take necessary steps at your request prior to entering into such a contract.<br/>
   <br/>•	Legal obligation: where processing of personal data is required by law. We are required by law to retain certain account opening information and personal data of our customers beyond the date such customers cease to carry on business with us.<br/>
   <br/>•	Legitimate interest: in order to protect the vital interests of other data subjects, and in order to carry out the purposes of our business. In addition to this, we have a legitimate interest to prevent fraud, money laundering and to verify identity of data subjects, in order to protect our customers and business, to understand how people interact with our Sites, to provide communication which we think will be of interest to you and to determine the effectiveness of promotional campaigns and advertising.<br/>
   <br/>•	Public interest: such processing is necessary for the performance of a task carried out in the interest of the public on in exercise of an official public mandate vested on us.<br/>

                  
                </Typography>


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
INFORMATION WE MAY COLLECT FROM YOU
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
                    mt: 1
                    }}>
             When you use the Sites or Services, we collect and store your personal data which is provided by you from time to time. 
Personal data/ information in this context shall include all data such as: any means of information relating to an identified or identifiable natural person who can be identified by:
<br/>•	A name;<br/>
<br/>•	An identification number;<br/>
<br/>•	Location data, an online identifier;<br/>
<br/>•	Address, a photo, an email address;<br/>
<br/>•	Facial recognition data;<br/>
<br/>•	Bank details;<br/>
<br/>•	Other unique identifiers such as but not limited to MAC address, IP address, IMEI number, IMSI number, SIM.<br/>
For the purpose of accessing our Services, the personal data we may collect include: your full legal names, marital status, title, date of birth, gender, photo, facial recognition data, business name, email address, mailing address, telephone number, bank account number, payment card details, bank verification number, national identification number, international passport number, means of identification, guarantors contact details, bank statements, usernames, password, your preferences, interests, feedback and survey responses, preference in receiving marketing information from us and our third parties and your communication preferences, etc. <br/>
Our primary goal in collecting the above stated personal data is to provide you with a safe, efficient, smooth and customised experience. This allows us to provide Services and features that most likely meet your needs, and to customise the Sites to make your experience safer and easier. 

                  
                </Typography>  
                
                
                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
HOW WE COLLECT INFORMATION
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
            We collect information you provide directly to us, for example, we collect information when you register or log on to the Sites, create an account, subscribe to a Service, participate in any interactive features on our Services, fill out a form, take part in surveys, post on our message boards, upload any documentation, request customer support, make an enquiry, communicate with us by email, phone or post, interact with us on social media, etc.
We will also collect your information where you partially complete and/or abandon any information inputted in the Sites and may use this information to contact you to remind you to complete any outstanding information.
Every computer connected to the internet is given a domain name and a set of numbers that serve as that computer’s internet protocol “IP address”. When you use the Sites, our web servers automatically recognize your domain name and IP address. The domain name and IP address reveals nothing personal about you other than the IP address from which you have accessed the Sites. We are able to see information relating to your browsing patterns and technical data about the equipment you use to access the website through the use of cookies, server logs and other similar technologies. You can select your preference from the cookies settings on any of our websites. 
We may also collect technical data from third parties/ public sources such as analytics providers, identity verification providers, advertising networks, search information providers. We may obtain contact, financial and transaction data from providers of technical, payment, credit referencing and delivery services based both inside and outside Nigeria. We utilise third-party service providers to secure information related to financial crime, fraud, sanctions and politically exposed persons.
We do not own personal data provided and will only store such data for a period reasonably needed and we will do our best to ensure that such personal data is secured against all foreseeable hazards and breaches such as theft, cyber-attack, viral attack, unauthorised dissemination, manipulation of any kind, damage by rain, fire or exposure to other natural elements. 
We will not sell, share, transfer or rent out any personal information to others in ways different from what is disclosed in this Policy, and our terms and conditions of use. We may share generic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers.
Providing us with information about others
If you provide us with personal data about someone else, you are responsible for ensuring that you have provided the required notices and have obtained the individual’s explicit consent to provide us with the personal data and that you explain to them how we collect, use, disclose and retain their personal data or direct them to read our Policy. 

                  
                </Typography>


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
INFORMATION WE COLLECT FROM OTHER SOURCES
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
           In order to provide you with access to the Services, or to provide you with better service in general, we may combine information obtained from other sources (for example, a third-party developer whose application you have authorised) and combine that with information we collect through the Sites.
                  
                </Typography>

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
HOW WE USE YOUR PERSONAL DATA
                 
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
      The purpose of collecting your personal data is to give you an efficient, enjoyable, secure and seamless customer experience. 
We may use your personal data for the following purposes:
<br/>•	To provide the requested Services and support to you;<br/>
<br/>•	To process transactions and send notices about your transactions to requisite parties;<br/>
<br/>•	To verify your identity; <br/>
<br/>•	To resolve disputes and troubleshoot problems;<br/>
<br/>•	To manage risk, detect, prevent, and/or remediate fraud or other potentially prohibited or illegal activities;<br/>
<br/>•	To detect, prevent or remediate violations of policies or applicable user agreements;<br/>
<br/>•	To improve our services by implementing aggregate customer preferences;<br/>
<br/>•	To manage and protect our information technology infrastructure;<br/>
<br/>•	To contact you at any time through your provided telephone number, email address or other contact details; <br/>
<br/>•	To notify you about activities on your account, troubleshoot problems with your account and collect fees or monies owed; <br/>
<br/>•	To monitor traffic patterns and usage of the Sites to help to improve the Sites design and layout;<br/>
<br/>•	To record and store communications made via phone, skype or the website chat function;<br/>
<br/>•	To personalise your experience on our Sites or communications/advertising;<br/>
<br/>•	To provide customer service, including to respond to your enquiries and fulfil any of your requests for information;<br/>
<br/>•	To send you important information regarding the services and/or other technical notices, updates, security alerts, support and administrative messages;<br/>
<br/>•	To poll your opinions through surveys or questionnaires<br/>
<br/>•	As Almond believes to be necessary or appropriate<br/>
<br/>•	To comply with a legal obligation. This applies where the processing is necessary for Almond to comply with the law;<br/>
<br/>•	To enforce or apply this Policy<br/>
<br/>•	To protect Almond’s legitimate interests, privacy, property or safety, and/or those of a third party as long as your rights do not override those interests.<br/>
We may monitor and record our communications with you, including e-mails and phone conversations for training, quality assurance purposes, and to meet our legal and regulatory obligations in general.
<br/><strong>Fraud prevention</strong><br/>
We may process your personal data on the basis that we have a legitimate interest to prevent fraud and money laundering, and to verify your identity, in order to protect our business and to comply with laws that apply to us. Such processing is also a contractual requirement of the services you have requested.
We may carry out fraud prevention checks using a fraud prevention database. If false or inaccurate information is provided, and/or fraud is identified, details will be passed to the Central Bank of Nigeria and the Economic and Financial Crimes Commission. Additionally, law enforcement agencies may access and use this information.
<br/><strong>Data analytics and bench-marking</strong><br/>
We may use information generated and stored during your use of our Services for our legitimate activities to enable us to give you the best service and/or solutions and the best experience. These purposes include to:
<br/>•	Deliver advertising or information to you which may be useful to you, based on your use of preferences<br/>
<br/>•	Carry out research and development to improve our Services;<br/>
<br/>•	Develop and provide new and existing functionality and Services (including statistical analysis, benchmarking and forecasting Services)<br/>
<br/>•	Provide you with location-based Services (for example location relevant content) where we collect geo-location data to provide a relevant experience.<br/>
Whenever we use your information for our legitimate interests, we will ensure that your information is processed on a pseudonymised basis and displayed at aggregated levels, which will not be linked back to you or to any living individual.
You have the right to object to processing based on our legitimate activities but if you object, this may affect our ability to provide certain Services and/or solutions for your benefit.

                </Typography>
                
                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
 YOUR RIGHTS AS A DATA SUBJECT
                 
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       Your personal data is protected by legal rights enshrined in Data Protection Laws. These rights include the following:
   <br/>•	The right to be told how we use your personal data and obtain access to your personal data;<br/>
   <br/>•	The right to have your personal data rectified or erased or place restrictions on processing your personal data;<br/>
   <br/>•	The right to object to the processing of your personal data e.g. where the processing is based on our legitimate interests. Please note that this may prevent us from continuing to provide Services to you;<br/>
   <br/>•	The right to have any information you provided to us on an automated basis returned to you in a structured, commonly used and machine-readable format, or sent directly to another organisation, where technically feasible (“data portability”);<br/>
   <br/>•	Where the processing of your personal data is based on your consent, the right to withdraw that consent subject to legal or contractual restrictions; <br/>
   <br/>•	The right to object to any decisions based on the automated processing of your personal data, including profiling; <br/>
   <br/>•	The right to lodge a complaint with the supervisory authority responsible for data protection matters.<br/>
Please note that if you request for a copy of your personal data, you may be required to pay a fee.
If you would like to exercise any of the above stated rights, please follow the following procedures:
<br/>•	Put your request in writing and send it to us through your usual registered channel (e.g. by registered email).<br/>
<br/>•   Specify the right you wish to exercise.<br/>
<br/>•	You can also access the Data Subject Access Request (DSAR) portal on our website.<br/>
For more information or to exercise your data protection rights please, please contact our Data Protection Officer at <strong>support@almondnigeria.com.</strong>
<br/>We will endeavour to process all subject access requests within thirty (30) days and if any further extension is required, we will communicate same through existing consented channels – at no cost. However, please note that you may continue to receive existing communications for a transitional period whilst we update your preferences.

                </Typography>

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
 YOUR RIGHTS AS A DATA SUBJECT
                 
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       Your personal data is protected by legal rights enshrined in Data Protection Laws. These rights include the following:
   <br/>•	The right to be told how we use your personal data and obtain access to your personal data;<br/>
   <br/>•	The right to have your personal data rectified or erased or place restrictions on processing your personal data;<br/>
   <br/>•	The right to object to the processing of your personal data e.g. where the processing is based on our legitimate interests. Please note that this may prevent us from continuing to provide Services to you;<br/>
   <br/>•	The right to have any information you provided to us on an automated basis returned to you in a structured, commonly used and machine-readable format, or sent directly to another organisation, where technically feasible (“data portability”);<br/>
   <br/>•	Where the processing of your personal data is based on your consent, the right to withdraw that consent subject to legal or contractual restrictions; <br/>
   <br/>•	The right to object to any decisions based on the automated processing of your personal data, including profiling; <br/>
   <br/>•	The right to lodge a complaint with the supervisory authority responsible for data protection matters.<br/>
Please note that if you request for a copy of your personal data, you may be required to pay a fee.
If you would like to exercise any of the above stated rights, please follow the following procedures:
<br/>•	Put your request in writing and send it to us through your usual registered channel (e.g. by registered email).<br/>
<br/>•   Specify the right you wish to exercise.<br/>
<br/>•	You can also access the Data Subject Access Request (DSAR) portal on our website.<br/>
For more information or to exercise your data protection rights please, please contact our Data Protection Officer at <strong>support@almondnigeria.com.</strong>
<br/>We will endeavour to process all subject access requests within thirty (30) days and if any further extension is required, we will communicate same through existing consented channels – at no cost. However, please note that you may continue to receive existing communications for a transitional period whilst we update your preferences.

                </Typography>
        

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
RETENTION OF YOUR DATA
                 
  </Typography>
             
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       We will not retain your personal data for longer than is necessary for the purposes for which such personal data is processed. This means that your personal data will only be retained for as long as it is still required to provide you with the Services or is necessary for legal reasons. When calculating the appropriate retention period of your personal data we consider the nature and sensitivity of the personal data, the purposes for which we are processing such personal data, and any applicable statutory/regulatory retention periods. Using these criteria, we regularly review the personal data that we hold and the purposes for which such is held and processed. Our Payment Card Industry Data Security Standard (“PCIDSS”) obligation means that we are obliged to retain personal data for a minimum of ten (10) years from the end date of our business relationship with you. 
When we determine that personal data can no longer be retained (or where you request that we delete your personal data in accordance with your rights contained in Data Protection Laws) we ensure that such personal data is securely deleted, anonymized or destroyed. However, please note that, in some circumstances we may decide to retain your personal data as may be reasonably necessary in accordance with the provisions of Data Protection Laws. In such circumstances, we will anonymize your personal data before retaining same.
<br/>Please see details of our data retention and disposal process below: <br/>
<br/><TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type of data</TableCell>
            <TableCell>Retention Period</TableCell>
            <TableCell>Disposal Process</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Electronic storage on database</TableCell>
            <TableCell>10 years (regulatory reasons)</TableCell>
            <TableCell>Programmatic (automatic) process to remove, at least on a quarterly basis, personal data that exceeds business retention requirements/reviews conducted at least on a quarterly basis</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hardcopy data (receipts/faxes)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Cross-cut shredded/incinerated, pulped</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hard drives (back-up)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Secure wipe program/degauss</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Tape Media (back-up)</TableCell>
            <TableCell>10 years</TableCell>
            <TableCell>Physically destroy</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>System and network logs</TableCell>
            <TableCell>.</TableCell>
            <TableCell>year</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <br/>On at least a quarterly basis, we systematically remove and destroy all cardholder data that has exceeded its retention period, and review and ensure the remaining stored cardholder data remains within the formal retention requirements. 
<br/>Wherever the primary account number (“PAN”) is stored, whether electronically or on paper, it is masked. The first six and last four digits are the maximum number of digits that may be displayed. Certain members of the operations and Service delivery units have a legitimate business need when dealing with customer/cardholder enquiries to access the PAN. Wherever the PAN is stored (including in logs, removable media, etc.), it is made unreadable by means of one-way hashes. Cardholder data is never stored on removable media and when removable physical storage media (including documents, faxes, and electronic media) are no longer required (i.e. they have passed their retention periods), they are destroyed. <br/>
                </Typography>


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
ACCURACY OF YOUR DATA
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       It is important that the personal data Almond holds about you is accurate and current. Please keep Almond informed if any aspect of your personal data changes at any time during your relationship with us. On our customer facing products, you can easily update your personal data yourself or alternatively contact our Data Protection Officer via <strong> support@almondnigeria.com</strong> when you want to exercise your right of rectification.
                </Typography>    


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
SECURITY OF YOUR DATA
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
        In order to protect your personal data, we have put in place appropriate organisational and technical security measures. These measures include storing data on a dedicated and secure server with at least 256-bit encryption, restricting access to your personal data to certain employees, ensuring that our internal information technology systems are suitably secure, and implementing procedures to deal with any suspected data breach.<br/>
   <br/>In the unlikely event of a data breach, Almond will take steps to mitigate any loss or destruction of data and, if appropriate, will notify you and any applicable authority of such a breach.

                </Typography>   



                         <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
DATA TRANSFERS AND SHARING
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       Due to the fact that we operate in a regulated environment, we cannot ensure that all your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Policy. By way of example (without limiting the foregoing), we may be forced to disclose information to the government, regulatory bodies, law enforcement agencies, and third parties for the performance of a task carried out in the interest of the public, for the protection of your vital interest, for the performance of a contract which you are a party to and also where you have expressly given us written consent to disclose same.
We may need to pass your information to third party service providers which maintain, administer or develop the Sites on our behalf and the information will only be provided for such limited purposes and as detailed below. Additionally, we may provide aggregate statistics about our customers, sales, traffic patterns and related website information to reputable third-parties, but these statistics will include no personally identifiable information.
<br/>Almond may transfer your personal data to third parties (“Third Party Providers”) of the following types: <br/>
<br/>•	Companies providing identity or financial validation services; <br/>
<br/>•	Financial product providers; <br/>
<br/>•	Payment services companies acting on your, or our behalf; <br/>
<br/>•	Banks; <br/>
<br/>•	Companies providing analytics services; <br/>
<br/>•	Data, service and software providers; <br/>
<br/>•	Companies collecting and publishing customer reviews; <br/>
<br/>•	Marketing services companies <br/>
<br/>•	Regulatory and law enforcement bodies. <br/>
A few of our identity verification Third Party Providers collect your personal data via our Sites through the use of Apple Inc. 's (“Apple”) TrueDepth Application Programming Interface (“TrueDepth API”). As a result of the integration of our Sites with such Third Party Providers, our Sites make use of automatically collected information using the device camera on your Apple mobile device and the TrueDepth API provided by Apple. 
<br/>The use of your personal data collected as a result of this is to track your facial features, and control the augmented reality (AR) experience. We use ARKit to capture your face 3D spatial orientation and facial expressions. In doing this, we use this data to ensure that the picture (selfie) being taken is of a live user for authentication and fraud reduction purposes. The ARKit information is processed entirely locally and the spatial orientation/facial expression data is not submitted to any third (or first) parties. None of the information collected by the TrueDepth API ever leaves your mobile device nor is it persistently stored on the device. 
We will do our reasonable best to ensure personal data provided by you to us and shared with a Third Party Provider is done in accordance with the provisions of Data Protection Laws. We will also reasonably ensure that such Third Party Providers with whom we share your personal data will ensure the security of the same as provided by this Policy and in accordance with Data Protection Laws.  <br/>

                </Typography>   


                 <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
CONFIDENTIALITY
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       You further acknowledge that the Sites may contain information which is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal data to any third party nor use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed Services.
   <br/>We take all necessary precautions to protect your personal information both online and off-line. It is important for you to protect against unauthorised access to your password, your mobile phone or computer. Be sure to log off from the Sites when you are using a shared phone/computer. We also protect your personal data off-line. Access to your personal data is limited to employees, agents or partners and third parties with whom we are working who we reasonably believe will need that information to provide the Services to you. <br/>

                </Typography>    

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
  COOKIES
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
      We use data collection devices such as ‘cookies’ on certain pages of the Sites. Cookies are small files stored on your hard drive that assist us in providing Services customised to your requirements and tastes. We also offer certain features that are only available through the use of a ‘cookie’. Cookies can also help us provide information, which is targeted to your interests. Cookies may be used whether you choose to register with Us or not. 
   <br/>We also use cookies to allow you to enter your password less frequently during a session. Most cookies are ‘session cookies’, meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Sites and you may be required to re-enter your password more frequently during a session. A cookie cannot read data off your hard disk or read cookie files created by other sites. Use of a cookie is in no way linked to any personally identifiable information while on the Sites. Once you close your browser, the cookie simply terminates. For instance, by setting a cookie on your browser, you would not have to log in a password more than once, thereby saving time while on the Sites. <br/>
You can choose whether to accept cookies by changing the settings of your browser. You can reset your browser to refuse all cookies or allow your browser to show you when a cookie is being sent. If you reject the cookies on the Sites, you may still be able to use the Sites, but it shall be limited to certain minimal functionality. The only drawback to this is that you may be limited to some areas of Sites or limited to certain functions of the Sites.

                </Typography>   
                

                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
JOB APPLICANTS
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
     If you apply for a job at Almond, you will be asked to submit information to Almond such as your name, contact details, information about your education and work history and any other background information that might be relevant to your application or that you choose to share with us. If you do not provide this information to us, we might not be able to process your application.
   <br/>We will use this information to assess your application and candidacy for the position you have applied for. Without limiting the generality of the foregoing, this may include:<br/>
   <br/>•	Evaluating your skills and experience in relation to the qualification required for the job you have applied for<br/>
   <br/>•	Tracking feedback and interactions, we have with you throughout the recruiting process. We may also use your information when analysing our internal recruitment processes to determine:<br/>
   <br/>•	Which recruitment sources to focus on;<br/>
   <br/>•	How to improve integration and training programs for new hires with different educational backgrounds and work experience<br/>
   <br/>•	How to enhance our interview model to improve hiring quality.<br/>
The information you provide may also be used to communicate with you about Almond’s events and to send you publications that we think may be of interest to you.
<br/>We might share your personal data with other companies in our group as well as with third parties such as recruitment service providers, background check providers and information technology system providers. These Almond group companies and third parties might be located in a different country than your country of residence.<br/>
We will keep your application data for up to one (1) year. If you no longer wish for Almond to process your personal data or if you wish to exercise any of your rights as a data subject, please contact <strong>support@almondnigeria.com</strong>. 

              
                </Typography> 




                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
DISCLAIMER
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
      We will keep your information secure by taking appropriate technical and organisational measures against its unauthorised or unlawful processing and against its accidental loss, destruction or damage. We will do our best to protect your personal data, but we cannot guarantee the security of your personal data which is transmitted to other websites via an internet or similar connection. 
   <br/>If we have given you (or you have chosen) a password to access certain areas of the Sites please keep this password safe, we will not share this password with anyone. <br/>
   As a user of the Services, you understand and agree that you assume all responsibility and risk attached to safeguarding your account with us. You shall at no time whatsoever disclose your password to anyone, nor shall you allow anyone make use of your account.
              
                </Typography> 


                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
UPDATES TO THE PRIVACY POLICY
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       We are constantly trying to improve our Sites and Services, so we may need to change this Policy from time to time as well. We will alert you of material changes by, for example, placing a notice on our websites and/or by sending you an email (if you have registered your e-mail details with us) when we are required to do so by applicable law. We reserve the right to update this Policy as we deem fit, from time to time, without any intimation to you and your continued use of the Sites will signify your acceptance of any amendment to these terms. 
Our updated terms will also be displayed on our website (www.almondnigeria.com) It is your responsibility to check this Privacy Policy from time to time to verify such updates.
If you believe at any time that we have not handled your personal data in accordance with this Policy, please contact our Data Protection Officer.
We have appointed a Data Protection Officer (DPO) who is responsible for dealing with all such concerns, in addition to overseeing questions relating to this Policy and handling requests in relation to the exercise of your rights. If you have any concerns or questions, please contact the Data Protection Officer using the details set out below.

                </Typography> 



                <Typography
    sx={{
      fontSize: 16,
      fontWeight: 900,
      mt: 3,
    }}
  >
     CONTACT
                 
  </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400,
               //textAlign:"center",
                    mt: 1
                    }}>
       Data Protection Officer
   <br/>Almond Nigeria<br/>
   <br/>No.10 Omowumi Street, New Oko-oba, Alimosho Lagos State. <br/>
   <br/><strong>support@almondnigeria.com</strong><br/>

   <br/>If you have any questions, comments and requests regarding your privacy and rights, please let us know how we can help.
Last Updated: 1st March 2024.


                </Typography> 
                      
                      
    </>
    )
   }
              
     

           
         
      </Container>
    </Box>
  );
}
