import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
 "https://ik.imagekit.io/zjvk6l5gp/logo1%20(1).svg?updatedAt=1708955713076",
 "https://ik.imagekit.io/zjvk6l5gp/logo%20(4).png?updatedAt=1708955713531",
//  "https://ik.imagekit.io/zjvk6l5gp/IMG_0313.JPG?updatedAt=1708955713410"
];

const logoStyle = {
  width: '150px',
  height: '50px',
  margin: '0 32px',
  filter: 'grayscale(100%)',
  opacity: 0.7,
};
const logoStyle2 = {
  width: '100px',
  height: '100px',
  margin: '0 32px',
  filter: 'grayscale(100%)',
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = darkLogos

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Our Trusted Partners
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 3.5,  }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={index === 2 ? logoStyle2 : logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
