import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

const items = [
    "https://ik.imagekit.io/zjvk6l5gp/962shots_so.png?updatedAt=1709045165936",
    ""
]

export default function Aboutushero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
      
        backgroundImage: "linear-gradient(180deg, rgba(125, 93, 246, 0.3), #fff)",
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
       
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          width: "100%"
        }}
      >

         <Typography
              component="span"
              variant="h1"
              sx={{
               
                color: "#20136E",
                fontSize: {xs: 30, sm: 42}
              }}
            >
              About us
            </Typography>
       <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              mt: 3,
              fontSize: {xs: 45, sm: 68}
            }}
          >
            We changed the concept of banking
            
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary" sx={{
               
               //color: "#7D5DF6",
               fontSize: 20,
               mt: 2,
               width: "67%",
               fontWeight: 800,
               fontSize: {xs: 17, sm: 20}
             }}>
         Almond wasn't only built to help secure your funds and investment, it was also built to help you build wealth while you spend or save, 
         Our goal is to ensure all our attain financial freedom.
          </Typography>
          <Button
              
                component="a"
                href="https://apps.apple.com/ng/app/almond-app/id6479360310"
                target="_blank"
                sx={{backgroundColor: "#20136E", borderRadius: "9px", color: "white", width: {xs: 125, sm: 140} , height: 45, borderRadius: 20, mt: 3,
                '&:hover': {
                    backgroundColor: "black",
                    borderColor: "rgba(125, 93, 246, 1)",
                    boxShadow: `0 0 24px rgba(125, 93, 246, 0.3)`,
                  },
            
            }}
               
                
              >
                Try Almond
              </Button>


    { !isMobile ? (
        <>
         <Grid container spacing={6} sx={{mt: 18}}>
   
   <Grid  xs={12} md={6} display  
           sx={{
           
           }}
           >
             <div>
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:40, ml: 31, fontWeight: 900 }}>
             Our Mission
                 
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:21, ml: 15, fontWeight: 500, mt:2, textAlign: "center" }}>
               At Almond, we are driven by a bold and transformative vision: to revolutionize the way people think about and interact with their finances. 
               Our journey began with a simple yet powerful idea that financial security should not be an exclusive privilege, but a universal right. With this principle guiding us, we embarked on a mission to redefine the norms of traditional banking and investing,
               Our mission extends beyond mere financial transactions  and we are committed to fostering a community of empowered individuals who share a common goal: to achieve lasting financial freedom.
               At Almond, we believe that financial freedom is not just a destination it's a journey. And we are honored to walk alongside you every step of the way, as we work together to build a brighter, more equitable future for generations to come.
               </Typography>
              
             </div>
             
           
            
           </Grid> 

<Grid
       item
       xs={12}
       md={6}
       sx={{ display: "flex", width: '100%' ,
    //    backgroundImage: "radial-gradient(circle, rgba(125, 93, 246, 0) 0%, rgba(125, 93, 246, 0.3) 80%)",
    //    borderRadius: "90%", 
    
    }}
       
     >

         
<Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         ml: 10,
         mt:20
         
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '70%',
             height: '70%',
             resizeMode: 'cover',
             
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>


      

     </Grid>
    
   </Grid>
   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:50,  fontWeight: 900, mt: 20 }}>
             Founders
                 
               </Typography>
    <Grid container spacing={6} sx={{mt: 12}}>
   
   <Grid  xs={12} md={6} display  
           sx={{

           }}
           >
             <div>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:22, ml: 20, fontWeight: 400, textAlign: "center" }}>
               Unlock the full potential of your banking experience with Almond. 
                 Seamlessly integrated with our banking services, 
                 our app offers a suite of features designed to empower you and simplify your financial life.

                 
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:{xs: 22, sm: 20}, ml: 30, fontWeight: 700, mt: 2}}>
             Ceo & Co-founder - Ekok Okpokam
               </Typography>
             </div>
             
           
            
           </Grid> 

<Grid
       item
       xs={12}
       md={6}
       sx={{ display: "flex", width: '100%' }}
     >

         
      <Card
         variant="outlined"
         sx={{
         width: 300,
         height: 300,
           display: 'flex',
           borderColor:  "rgba(125, 93, 246, 0.4)",
           boxShadow: `0 0 24px rgba(125, 93, 246, 0.4)`,

         //   borderColor: "#20136E",
         //   boxShadow: `0 0 24px #20136E`,
          
           borderRadius: '50%', 
             overflow: 'hidden', 
             mt: -10,
             ml : 16
            
         }}
       >
        <Box
       
       sx={{
         m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/okpoks.jpeg?updatedAt=1709039440022'
         }
         style={{ 
             width: '100%',
             height: '100%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>
       </Card> 


      

     </Grid>
    
   </Grid>

   <Grid container spacing={6} sx={{mt: 28}}>
   
  

<Grid
       item
       xs={12}
       md={6}
       sx={{ display: "flex", width: '100%' }}
     >

         
      <Card
         variant="outlined"
         sx={{
         width: 370,
         height: 370,
           display: 'flex',
           borderColor:  "rgba(125, 93, 246, 0.4)",
           boxShadow: `0 0 24px rgba(125, 93, 246, 0.4)`,

         //   borderColor: "#20136E",
         //   boxShadow: `0 0 24px #20136E`,
          
           borderRadius: '50%', 
             overflow: 'hidden', 
             mt: -10,
             ml : 16
            
         }}
       >
        <Box
       
       sx={{
         m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         // backgroundImage: (theme) =>
         //   theme.palette.mode === 'light'
         //     ? items[selectedItemIndex].imageLight
         //     : items[selectedItemIndex].imageDark,
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/IMG_8630%203.jpeg?updatedAt=1709046272578'
         }
         style={{ 
             width: '100%',
             height: '100%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>
       </Card> 


      

     </Grid>
    

     <Grid  xs={12} md={6} display  
           sx={{

           }}
           >
             <div>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:22, ml: 20, fontWeight: 400 , textAlign: "center"}}>
               Almond is more than just a platform; it's a catalyst for change a beacon of hope for those seeking to break free from the constraints of financial insecurity. 
               Built upon a foundation of trust, innovation, and integrity
                 
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:20, ml: 30, fontWeight: 700, mt: 2}}>
          CTO & Co-founder - Ogbodo Hillary
               </Typography>
             </div>
             
           
            
           </Grid> 
   </Grid>
   </>
   ) : 
   
   (
    <>
 
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:35, fontWeight: 900,textAlign: "center", display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5  }}>
             Our Mission
                 
               </Typography>
               <Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         mt:1,
         display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
         // backgroundImage: (theme) =>
         //   theme.palette.mode === 'light'
         //     ? items[selectedItemIndex].imageLight
         //     : items[selectedItemIndex].imageDark,
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '30%',
             height: '30%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 500, mt:3, textAlign: "center",display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center', }}>
               At Almond, we are driven by a bold and transformative vision: to revolutionize the way people think about and interact with their finances. 
               Our journey began with a simple yet powerful idea that financial security should not be an exclusive privilege, but a universal right. With this principle guiding us, we embarked on a mission to redefine the norms of traditional banking and investing,
               Our mission extends beyond mere financial transactions  and we are committed to fostering a community of empowered individuals who share a common goal: to achieve lasting financial freedom.
               At Almond, we believe that financial freedom is not just a destination it's a journey. And we are honored to walk alongside you every step of the way, as we work together to build a brighter, more equitable future for generations to come.
               </Typography>
              

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:30,  fontWeight: 900, mt: 5 }}>
             Founders
                 
               </Typography>
        
              <Card
          variant="outlined"
          sx={{
          width: 180,
          height: 180,
            display: 'flex',
            borderColor:  "rgba(125, 93, 246, 1)",
            boxShadow: `0 0 24px rgba(125, 93, 246, 0.8)`,
 
          //   borderColor: "#20136E",
          //   boxShadow: `0 0 24px #20136E`,
           
            borderRadius: '50%', 
              overflow: 'hidden', 
              mt: 4,
             
             
          }}
        >
         <Box
        
        sx={{
          m: 'auto',
          width: "100%",
          height: "auto",
          backgroundSize: 'contain',
          // backgroundImage: (theme) =>
          //   theme.palette.mode === 'light'
          //     ? items[selectedItemIndex].imageLight
          //     : items[selectedItemIndex].imageDark,
        }}
      >
         <img
          src={
              'https://ik.imagekit.io/zjvk6l5gp/okpoks.jpeg?updatedAt=1709039440022'
          }
          style={{ 
              width: '100%',
              height: '100%',
              resizeMode: 'cover',
        cursor: 'pointer',
        height:"auto"
        }}
          alt="apple"
          
        />
 
 </Box>
        </Card> 
 
        <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, textAlign: "center", width: "70%" , display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5
                    }}>
                Unlock the full potential of your banking experience with Almond. 
                  Seamlessly integrated with our banking services, 
                  our app offers a suite of features designed to empower you and simplify your financial life.
 
                  
                </Typography>
                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14, fontWeight: 700, mt: 1,  display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
                 Ceo & Co-founder - Ekok Okpokam
                </Typography>


                <Card
          variant="outlined"
          sx={{
          width: 180,
          height: 180,
            display: 'flex',
            borderColor:  "rgba(125, 93, 246, 1)",
            boxShadow: `0 0 24px rgba(125, 93, 246, 0.8)`,
           
            borderRadius: '50%', 
              overflow: 'hidden', 
              mt: 4,
             
             
          }}
        >
         <Box
        
        sx={{
          m: 'auto',
          width: "100%",
          height: "auto",
          backgroundSize: 'contain',

        }}
      >
         <img
          src={
            'https://ik.imagekit.io/zjvk6l5gp/IMG_8630%203.jpeg?updatedAt=1709046272578'
          }
          style={{ 
              width: '100%',
              height: '100%',
              resizeMode: 'cover',
        cursor: 'pointer',
        height:"auto"
        }}
          alt="apple"
          
        />
 
 </Box>
        </Card> 
 
        <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, textAlign: "center", width: "70%" , display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5
                    }}>
                 Almond is more than just a platform; it's a catalyst for change a beacon of hope for those seeking to break free from the constraints of financial insecurity. 
               Built upon a foundation of trust, innovation, and integrity
                 
                  
                </Typography>
                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14, fontWeight: 700, mt: 1,  display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
                CTO & Co-founder - Ogbodo Hillary
                </Typography>
    </>
    )
   }
              
     

           
         
      </Container>
    </Box>
  );
}
