import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';

const items = [

  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Virtual cards',
    description:
      'Explore our virtual cards in both Naira and Dollar options, perfect for handling your day-to-day transactions effortlessly.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/631shots_so.png?updatedAt=1708972538735',
    //imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
 
  {
    icon: <DevicesRoundedIcon />,
    title: 'Personalized savings account',
    description:
      'Create personalzed savings accounts to suit your financial goals and saving endeavors.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/466shots_so.png?updatedAt=1708972535719',
    // imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },

  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Smart spending',
    description:
      'Track your daily, weekly, monthly and yearly spending with ease.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/828shots_so.png?updatedAt=1708972539477',
    //imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  
  {
    icon: <DevicesRoundedIcon />,
    title: 'Bills splits & Requests',
    description:
      "Effortlessly request and split bills among friends, while also enjoying the convenience of splitting expenses within the vibrant Almond community. Whether it's sharing the cost of a meal or pooling resources for a group activity, our platform makes collaborative finances a breeze.",
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/83shots_so.png?updatedAt=1709114857569',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Loan service',
    description:
      'Almond extends loans to both students and small-scale business owners, with a focus on providing accessible financial support. However, at present, the loan facilities are exclusively available to students.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/701shots_so.png?updatedAt=1714204847066',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Credit scores',
    description:
      'As the pioneering private credit score issuing service in Nigeria, Almond introduces a revolutionary system for evaluating creditworthiness and facilitating accessible loans. This innovative platform will not only benefit Almond but also empower other financial systems across Nigeria to reduce risk of giving out bad loans.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/778shots_so.png?updatedAt=1714204847068',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'All in one physical cards',
    description:
      "Experience the convenience of Almond's All-in-One Physical cards, seamlessly linking all your bank cards to the Almond card.",
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/733shots_so.png?updatedAt=1714222535833',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Escrow account service',
    description:
      "The escrow account service allows users to create secure escrow accounts for their peer-to-peer online shopping transactions, such as purchasing goods and services from an online Instagram store, ensuring their safety from online shopping fraud.",
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/824shots_so.png?updatedAt=1714223240189',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
  
  {
    icon: <DevicesRoundedIcon />,
    title: 'And so much more.....',
    description:
      'And so much more ranging from, Tradition banking services, Bills payment, Almond pay, Personalized wallet withdrawal.',
    imageLight: 'https://ik.imagekit.io/zjvk6l5gp/799shots_so.png?updatedAt=1708972534420',
    //imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const [animationActive, setAnimationActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
   //setIsPaused(true)
  };
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (!isPaused) {
  //       setSelectedItemIndex((prevIndex) => (prevIndex + 1) % items.length);
  //     }
  //   }, 5000);

  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest('.MuiChip-root')) {
  //       setIsPaused(false);
  //     }
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     clearInterval(interval);
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [isPaused]);

 useEffect(() => {
   
    setAnimationActive(true);

  
    const timeout = setTimeout(() => {
      setAnimationActive(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [selectedItemIndex]);



  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>

      <Grid item xs={12} md={6} display  
              sx={{
             
              }}
              >
                <div>
                  <Typography component="h2" variant="h4" color="text.primary">
                  Banking features
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: { xs: 2, sm: 4 },}}
                  >
                    Unlock the full potential of your banking experience with Almond. 
                    Seamlessly integrated with our banking services, 
                    our app offers a suite of features designed to empower you and simplify your financial life
                  </Typography>
                </div>
                <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'auto' },
                      //  flexDirection: { xs: "none", sm: 'column' },
                        // justifyContent: { xs: "none", sm: 'center' },
                        // alignItems:  { xs: "none", sm: 'center' }, 
                        
                        }}>
                  {items.map(({ title }, index) => (
                    <Chip
                      key={index}
                      label={title}
                      onClick={() => handleItemClick(index)}
                      sx={{
                        borderColor: (theme) => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index ? 'rgba(125, 93, 246, 0.8)' : '';
                          }
                          return selectedItemIndex === index ? 'rgba(125, 93, 246, 0.8)' : '';
                        },
                        background: (theme) => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index ? 'none' : '';
                          }
                          return selectedItemIndex === index ? 'none' : '';
                        },
                        backgroundColor: selectedItemIndex === index ? 'rgba(125, 93, 246, 0.8)' : '',
                        '& .MuiChip-label': {
                          color: selectedItemIndex === index ? '#fff' : '',
                        },
                        // width: { xs: "auto", sm: 190 },
                        // height: { xs: 35, sm: 55 },
                        // borderRadius : { xs: "auto", sm: 190 }
                        
                        
                      }}
                    />
                  ))}
                </Grid>
                <Box
                  component={Card}
                  variant="outlined"
                  sx={{
                    display: { xs: 'auto', sm: 'none',
                    '&:hover': {
                      borderColor: "rgba(125, 93, 246, 1)",
                      boxShadow: `0 0 24px rgba(125, 93, 246, 0.3)`,
                    },
                    borderColor: animationActive ?  "rgba(125, 93, 246, 1)" :'none',
                    boxShadow: animationActive ? `0 0 24px rgba(125, 93, 246, 0.3)` : 'none',
                    // transition: 'box-shadow 0.3s',

                  
                  
                  },
                    mt: 4,
                  }}
                >
                  <Box
                    sx={{
                      backgroundImage: (theme) =>
                        theme.palette.mode === 'light'
                          ? items[selectedItemIndex].imageLight
                          : items[selectedItemIndex].imageDark,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      minHeight: 280,
                    }}
                  /> 
                  <Box
                
                sx={{
                  m: 'auto',
                  width: "100%",
                  height:"auto",
                  backgroundSize: 'contain',
                  // backgroundImage: (theme) =>
                  //   theme.palette.mode === 'light'
                  //     ? items[selectedItemIndex].imageLight
                  //     : items[selectedItemIndex].imageDark,
                }}
              >
                <img
                  src={
                    items[selectedItemIndex].imageLight
                  }
                  style={{ 
                    width: "100%",
                height:"auto"
                }}
                  alt="apple"
                  
                />

      </Box>
                  <Box sx={{ px: 2, pb: 2 }}>
                    <Typography color="text.primary" variant="body2" fontWeight="bold">
                      {selectedFeature.title}
                    </Typography>
                    <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                      {selectedFeature.description}
                    </Typography>
                    
                  </Box>
                </Box>
              {/* <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={2}
                  useFlexGap
                  sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
                >
                  {items.map(({ icon, title, description }, index) => (
                    <Card
                      key={index}
                      component={Button}
                      onClick={() => handleItemClick(index)}
                      sx={{
                        p: 3,
                        height: 'fit-content',
                        width: '100%',
                        background: 'none',
                        backgroundColor:
                          selectedItemIndex === index ? 'rgba(125, 93, 246, 0.2)' : undefined,
                        borderColor: (theme) => {
                          if (theme.palette.mode === 'light') {
                            return selectedItemIndex === index
                              ? "rgba(125, 93, 246, 0.7)"
                              : 'grey.200';
                          }
                          return selectedItemIndex === index ? 'rgba(125, 93, 246, 0.7)' : 'grey.800';
                        },
                      }}
                    >
                      <Box
                    
                        sx={{
                          width: '100%',
                          display: 'flex',
                          textAlign: 'left',
                          flexDirection: { xs: 'column', md: 'row' },
                          alignItems: { md: 'center' },
                          gap: 2.5,
                        }}
                      >
                        <Box
                          sx={{
                            color: (theme) => {
                              if (theme.palette.mode === 'light') {
                                return selectedItemIndex === index
                                  ? 'rgba(125, 93, 246, 0.7)'
                                  : 'grey.300';
                              }
                              return selectedItemIndex === index
                                ? 'rgba(125, 93, 246, 0.7)'
                                : 'grey.700';
                            },
                          }}
                        >
                          {icon}
                        </Box>
                        <div>
                          <Typography
                            color="text.primary"
                            variant="body2"
                            fontWeight="bold"
                          >
                            {title}
                          </Typography>
                          <Typography
                            color="text.secondary"
                            variant="body2"
                            sx={{ my: 0.5 }}
                          >
                            {description}
                          </Typography>
                        
                        </div>
                      </Box>
                    </Card>
                  ))}
                </Stack>  */}
                <Box sx={{ mt: 5 ,}}>
                    <Typography color="text.primary" variant="body2" fontWeight="bold" fontSize={20} sx={{display: { xs: 'none', sm: "center",}}}  >
                      {selectedFeature.title}
                    </Typography>
                    <Typography color="text.secondary" variant="body2" fontSize={16} sx={{ my: 0.5,display: { xs: 'none', sm: "center",}}}>
                      {selectedFeature.description}
                    </Typography>
                    
                  </Box>
              </Grid> 

<Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              borderColor: animationActive ?  "rgba(125, 93, 246, 1)" :'none',
              boxShadow: animationActive ? `0 0 24px rgba(125, 93, 246, 0.5)` : 'none',
              //transition: 'box-shadow 1.3s',
              '&:hover': {
                borderColor: "rgba(125, 93, 246, 1)",
                boxShadow: `0 0 24px rgba(125, 93, 246, 0.5)`,
              },
              //pointerEvents: 'none',
            }}
          >
            <Box
          
              sx={{
                m: 'auto',
                width: "100%",
                height: "auto",
                backgroundSize: 'contain',
                // backgroundImage: (theme) =>
                //   theme.palette.mode === 'light'
                //     ? items[selectedItemIndex].imageLight
                //     : items[selectedItemIndex].imageDark,
              }}
            >
               <img
                src={
                  items[selectedItemIndex].imageLight
                }
                style={{ 
                  width: "100%",
              cursor: 'pointer',
              height:"auto"
              }}
                alt="apple"
                
              />

</Box>
          </Card>

          {/* <img
                src={
                  items[selectedItemIndex].imageLight
                }
                style={{ 
                  width: "100%",
              cursor: 'pointer',
              height:"auto"
              }}
                alt="apple"
                
              /> */}
 

        </Grid>
        {/* <Box sx={{ mt: 5,display: { xs: "none", sm: 'flex' },
          flexDirection: { xs: "none", sm: 'column' },
          justifyContent: { xs: "none", sm: 'center' },
          alignItems:  { xs: "none", sm: 'center' }, }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold" fontSize={25}  >
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" fontSize={20} sx={{ my: 0.5, textAlign:"center" }}>
                {selectedFeature.description}
              </Typography>
              
            </Box> */}
           
      </Grid>
     
    </Container>
  );
}
