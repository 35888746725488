import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {Grow, Fade,Zoom} from '@mui/material';
const imgStyle = {
  width: '300px',
  height: 'auto',
  cursor: 'pointer',
  
  
};



const imgStyle2 = {
  width: '1000px',
  height: 'auto',
  cursor: 'pointer',
  
  
}


export default function Hero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        // backgroundImage:
        //   theme.palette.mode === 'light'
        //     ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
        //     : 'linear-gradient(#02294F, #090E10)',
        backgroundImage: "linear-gradient(180deg, rgba(125, 93, 246, 0.5), #fff)",//linear-gradient(180deg, #cee5fd, #BEAEFB, #fff)",//'linear-gradient(180deg, #CEE5FD, #FFF)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
        //backgroundColor:   "rgba(125, 93, 246, 0.6)",///"#7D5DF6"
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          width: "100%"
        }}
      >
          <Fade in={true}
          {...(true ? { timeout: 4000 } : {})}>
          <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: {xs: 35, sm: 50}
            }}
          >
            The All In One&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                // color: (theme) =>
                //   theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                color: "#7D5DF6",
                fontSize: {xs: 35, sm: 50}
              }}
            >
              Financial Service
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
          Discover unprecedented ease and empowerment with Almond the all-in-one bank app. 
             From intuitive interfaces to personalized solutions, cutting-edge tech, and swift support, we simplify, secure, and enhance your financial journey.
          </Typography>
        </Stack>
          </Fade>
        
        <Grow
    in={true}
 style={{ transformOrigin: '0 0 0 0 0' }}
 {...(true ? { timeout: 2000 } : {})}
    
  >
 <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: isMobile ?  200 : 700,
            
            width: isMobile ? '80%' : '90%',
            //backgroundImage: "https://ik.imagekit.io/zjvk6l5gp/128shots_so.png?updatedAt=1708943895345",
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#7D5DF6', 0.3)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
          })}
        >
           <img
                src={
                  "https://ik.imagekit.io/zjvk6l5gp/128shots_so.png?updatedAt=1708943895345"
                  // "https://ik.imagekit.io/zjvk6l5gp/984shots_so.png?updatedAt=1708943899794"
                }
                style={isMobile ? imgStyle : imgStyle2}
                alt="logo of almond"
              />




           </Box>
  </Grow>
       
       
{isMobile ? (
<Box  sx={{
        mt: 6,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
      }}
      >
  <Box
              sx={{
                position: "absolute",
                //top: 610
              }}
              
            >

<Link  href="https://apps.apple.com/ng/app/almond-app/id6479360310">
<img
                src={
                  "https://ik.imagekit.io/zjvk6l5gp/app-store.svg?updatedAt=1708947141030"
                }
                style={{ 
                  width: 120,
                  // width: { xs: '120px', sm: '1240px' },
               height: 'auto',
              cursor: 'pointer',
              }}
                alt="apple"
                
              />
          </Link>
             
              </Box>

              <Box
              sx={{
               position: "absolute",
               mt:10
              }}
              
            >
              <Link  href="https://play.google.com/store/apps/details?id=com.hillary3211.almond">
              <img
                src={
                  "https://ik.imagekit.io/zjvk6l5gp/google-play-badge-logo-svgrepo-com.svg?updatedAt=1708947140982"
                }
                style={{ 
                  width: 104,
              
              cursor: 'pointer',
              }}
                alt="android"
                
              />
                   </Link>
              </Box>
</Box>) : (


<Box 
 sx={{

// position: "absolute",
// top:1130,
mt: 6,
ml: "-5%"

 
}}
>
<Box
              sx={{
              
               
              position: "absolute",
              mt: 1,
              ml:-35
              }}
              
            >
               <Link  href="https://apps.apple.com/ng/app/almond-app/id6479360310">
               <img
                src={
                  "https://ik.imagekit.io/zjvk6l5gp/app-store.svg?updatedAt=1708947141030"
                }
                style={{ 
                  width: 240,
              cursor: 'pointer',
              }}
                alt="apple"
                
              />
          </Link>

          
             
              </Box>

              <Box
              sx={{
              mt: -5.5,
         position: "absolute",
         ml:15
           // left: 170,
           // top: -50,
           
               
                
              }}
              
            >
               <Link  href="https://play.google.com/store/apps/details?id=com.hillary3211.almond">
              <img
                src={
                  "https://ik.imagekit.io/zjvk6l5gp/google-play-badge-logo-svgrepo-com.svg?updatedAt=1708947140982"
                }
                style={{ 
                  width: 200,
               // height: 'auto',
              cursor: 'pointer',
              }}
                alt="android"
                
              />
                   </Link>
              </Box>
</Box>

)}
           
         
      </Container>
    </Box>
  );
}
