import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import Instagram from '@mui/icons-material/Instagram';
import { Phone } from '@mui/icons-material';
import { Email } from '@mui/icons-material';
const logoStyle = {
  width: '100px',
  height: 'auto',
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://www.almondnigeria.com/">Almond&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: '-5px' }}>
              <img
                src={
                  'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
                }
                style={logoStyle}
                alt="logo of sitemark"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our newsletter for weekly updates and promotions.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                // fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                inputProps={{
                  autocomplete: 'off',
                  ariaLabel: 'Enter your email address',
                }}
                
              />
              <Button   sx={{ flexShrink: 0 ,backgroundColor:"rgba(125, 93, 246, 0.9)", color: "white",  width: 60, fontSize: 10, height: 35 }}>
                Subscribe
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Product
          </Typography>
          <Link color="text.secondary" href="/">
            Features
          </Link>
          <Link color="text.secondary" href="/">
          Why us?
          </Link>
          <Link color="text.secondary" href="/">
            Membership
          </Link>
          <Link color="text.secondary" href="/">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link color="text.secondary" href="/aboutus">
            About us
          </Link>
          {/* <Link color="text.secondary" href="#">
            Careers
          </Link> */}
          <Link color="text.secondary" href="#">
            Press
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" href="/terms">
            Terms
          </Link>
          <Link color="text.secondary" href="/privacy">
            Privacy
          </Link>
          {/* <Link color="text.secondary" href="#">
            Contact
          </Link> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="/privacy">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="/terms">
            Terms of Service
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="/aboutus">
            About us
          </Link>
          
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          //useFlexGap
          sx={{
            color: 'text.secondary',
           display: { xs: "none", sm : "flex"}
          }}
        >
          
          <IconButton
            color="inherit"
            href="https://www.instagram.com/almondapp.ng/"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/almondincorp"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/almondnigeria/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.facebook.com/profile.php?id=61555046834721&mibextid=zLoPMf"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>

          <IconButton
            color="inherit"
            href="tel:+2348028798164"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <Phone />
          </IconButton>
          <IconButton
            color="inherit"
            href="mailto:support@almondnigeria.com"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <Email />
          </IconButton>
        </Stack>
       
      </Box>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          //useFlexGap
          sx={{
            color: 'text.secondary',
           display: { xs: "auto", sm : "none"}
          }}
        >
          
          <IconButton
            color="inherit"
            href="https://www.instagram.com/almondapp.ng/"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/almondincorp"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/almondnigeria/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.facebook.com/profile.php?id=61555046834721&mibextid=zLoPMf"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>

          <IconButton
            color="inherit"
           href="tel:+2348028798164"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <Phone />
          </IconButton>
          <IconButton
            color="inherit"
            href="mailto:support@almondnigeria.com"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <Email />
          </IconButton>
        </Stack>
    </Container>
  );
}
