import logo from './logo.svg';
import './App.css';
import {Routes, Route} from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import Aboutus from './Pages/AboutusPage';
import Privacy from './Pages/Privacy';
import Terms from './Pages/TermsPage';
function App() {
  const Main = () => (
    <Routes>
      <Route path='/' element={<LandingPage/>}></Route>
      <Route path='/aboutus' element={<Aboutus/>}></Route>
      <Route path='/privacy' element={<Privacy/>}></Route>
      <Route path='/terms' element={<Terms/>}></Route>
    </Routes>
  )

  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
