import React, { useState, useEffect } from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

const items = [
    "https://ik.imagekit.io/zjvk6l5gp/962shots_so.png?updatedAt=1709045165936",
    ""
]

export default function Termshero() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100); 

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
      
        backgroundImage: "linear-gradient(180deg, rgba(125, 93, 246, 0.1), #fff)",
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
       
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
          width: "100%"
        }}
      >

         <Typography
              component="span"
              variant="h1"
              sx={{
               
                color: "#20136E",
                fontSize: {xs: 30, sm: 42}
              }}
            >
              Terms and Conditions
            </Typography>
       
         


    { !isMobile ? (
        <>
         <Grid container spacing={6} sx={{mt: 18}}>
   
   <Grid  xs={12} md={6} display  
           sx={{

           }}
           >
             <div>
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:35, ml: 32, fontWeight: 900 }}>
            Term of use
                 
               </Typography>
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:21, ml: 15, fontWeight: 500, mt:2, textAlign: "center" }}>
               Please read these Terms of Use carefully before using the Almond Platform (“Platform”). Customers who are under 18 years of age should review these terms and conditions with a parent or guardian. 
               Your access to and use of the Platform by way of any Device is conditioned on your acceptance of and compliance with the provisions of this Terms of Use. 
               By your accessing or using the Platform you agree to be bound by these Terms of Use as amended at any time and posted on the website www.almondnigeria.com. 
               If you disagree with any part hereof, do not access the Platform. In addition, your use of the Platform shall be subject to any applicable regulatory guidelines or rules. 
               All such guidelines or rules are hereby incorporated by reference into the Terms of Use.
               </Typography>
              
             </div>
             
           
            
           </Grid> 

<Grid
       item
       xs={12}
       md={6}
       sx={{ display: "flex", width: '100%' }}
     >

         
<Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         ml: 10,
         mt:18
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '70%',
             height: '70%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>


      

     </Grid>
    
   </Grid>
   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 10, ml: -56 }}>
   DESCRIPTION OF THE ALMOND PLATFORM
                 
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
               The Platform is a financial service application that allows you purchase airtime, pay bills, send money, receive money and use card payment services via the web (www.almondnigeria.com) or mobile app (Almond apps). 
               All transactions performed are final and cannot be recalled except in the case of a proven fraud.
                 
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -58 }}>
               FAILED TRANSACTIONS AND REVERSALS
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   In the event of a failed transaction (i.e. user has been debited for transaction, but value was not received), please send the following details to <strong>support@almondnigeria.com</strong> for resolution: user’s mobile number, transaction amount, transaction date, transaction type and any other detail we would request to enable us resolve your complaint. We shall use our best endeavors to liaise with the applicable financial institution, telecommunication company and/or merchant to resolve your logged transaction issue.
               </Typography>


               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -110 }}>
               NOTICES
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   Your email address and phone number supplied at sign-up will be used as a means of communicating information, notices and documents to you. Any changes to such information should be logged as a ticket on <strong>support@almondnigeria.com</strong>
               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -66 }}>
               SECURITY AND UNAUTHORISED USE
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
  <br/> •	You are responsible for all transactions conducted using your computer (personal or otherwise), mobile phone, tablet or other electronic device (“Device”), payment card or account. It is your responsibility to keep all of these secure<br/>
  <br/>•	All passwords must be kept secret. You are liable for all activities undertaken using your username, associated password or Device. You are responsible for maintaining the confidentiality of all Account information and keeping your Device from unauthorized use.<br/>
  <br/>•	We accept no liability for any unauthorized use of your Device, payment card or account or any effects of same.<br/>
  <br/>•	In the event your Device has been stolen or there is an unauthorized access to your payment card or account, it is your responsibility to immediately notify your bank to restrict transactions on your card and account.<br/>
  <br/>•	In the event your Device has been stolen or there is an unauthorized access to your Almond account, it is your responsibility to immediately log as a ticket on <strong>support@almondnigeria.com</strong><br/>
  <br/>•	If you dispute any purchase or withdrawal on your card or account, you will be required to prove that the transaction was effected without your authorization and such transaction will be investigated by our Fraud Team once we receive a logged ticket on <strong>support@almondnigeria.com</strong><br/>

               </Typography>


               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -62 }}>
               SUSPENSION OF ACCESS TO PLATFORM
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   We hereby reserve the right to suspend, restrict, or terminate your access to the Platform (in whole or in part) without any notice or liability in the following circumstances:
   <br/> •	Upon receipt of instructions from you to suspend or terminate your access to the Platform<br/> 
   <br/> •	Upon receipt of notification from you that your Device has been lost or stolen<br/> 
   <br/> •	If we suspect the Platform is being used for fraudulent or other illegal activities.<br/> 
   <br/> •	If we are of the opinion that you have breached a provision contained in this Terms of Use.<br/> 
   <br/> •	If we are of the opinion that continued access to the Platform will be detrimental to our business.<br/> 
   <br/> •	If you provide any information that is untrue, inaccurate, not current or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete.<br/> 

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -92 }}>
               UNACCEPTABLE USE
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   You shall not and shall not permit any person using your Device or account to:
   <br/>•	Use this Platform in a way that causes or could cause damage or restrict the availability or accessibility of the Platform;<br/>
   <br/>•	Conduct any fraudulent or illegal activities via the Platform;<br/>
   <br/>•	You hereby understand and agree that if a fraudulent activity is associated with the operation of your account, We have the right to apply restrictions to your account and report to the appropriate law enforcement agencies.<br/>


               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -90 }}>
               PRIVACY STATEMENT
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   •	You acknowledge that by your use of the Platform, we shall be privy to and store some of your personal information (full name, mobile phone number and email address and some cases BVN).   <br/>
   <br/>•	We shall use our best endeavors to ensure that your personal information is secure on our systems.<br/>
   <br/>•	We may use your personal information for:
   <br/>o	providing you the service, processing transactions and sending you related information;
   <br/>o	providing, maintaining and improving our services;
   <br/>o	sending support and administrative messages to you;
   <br/>o	responding to your enquiries and requests;
   <br/>o	providing customer service;
   <br/>o	updating you on products, services, promotions, rewards, and events offered by us and third parties; and
   <br/>o	A better understanding of your needs by combining it with information collected from others with the objective of improving on our service delivery.   <br/>
   <br/>•	We may disclose any information we collect about you to third parties as set forth below:
   <br/>o	To regulatory bodies;
   <br/>o	To financial institutions;
   <br/>o	To companies, such as email service providers that perform marketing services on our behalf;
   <br/>o	To third parties to enable us provide Service to you; and
   <br/>o	If required to be disclosed under applicable law, or stock exchange regulation or by a government order, decree, regulation or rule or by a court order.
   <br/>•	You hereby consent to the processing, transfer and storage of your information during and after the use of the Platform.<br/>


               </Typography>



               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -106 }}>
               INDEMNITY
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
 •	You agree to indemnify Transatlantic Trusted Partners Limited, and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses due to or arising out of any transaction you process through the Platform.<br/>
   <br/>•	You agree to indemnify Transatlantic Trusted Partners Limited and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses arising from your violation of this Terms of Use<br/>

               </Typography>


               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -83 }}>
               LIMITATION OF LIABILITY
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   •	In no event shall we be liable for:
   <br/>o	unavailability of the Platform at any time or your inability to use the Platform.
   <br/>o	any form of delivery or failure thereof by a financial institution, merchant or telecommunication company listed on the Almond Platform.
   <br/>o	erroneous input of transaction details.
   <br/>o	any loss arising from any failure or delay in any mobile phone networks or internet service provider or defective Device.
   <br/>o	any special, indirect, incidental, punitive, or consequential damages, including loss of profits, however caused.<br/>
•	Under no circumstances shall it be implied that we endorse, sponsor, certify or otherwise guarantee the sale or use of any merchant’s products and/services listed on the Platform. Use and/or purchase of same shall be at your own risk.<br/>
<br/>•	WE SHALL USE OUR BEST ENDEAVOURS TO ENSURE THE PLATFORM FUNCTIONS OPTIMALLY AT ALL TIMES AND OUR SYSTEMS ARE FREE FROM VIRUSES, HOWEVER WE CANNOT ENSURE SUCH EXCLUSION AND NO LIABILITY IS ACCEPTED FOR SAME. HENCE IT IS RECOMMENDED YOU PUT IN PLACE YOUR OWN SECURITY MEASURES. WE DO NOT WARRANT THAT THE OPERATION OF THE PLATFORM WILL BE UNINTERRUPTED NOR ERROR FREE, NOR WILL IT BE 100% FRAUD OR FAIL PROOF.WE ARE PROVIDING THE SERVICE “AS IS” WITHOUT ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SERVICES OR ITS CONDITION, TIMELINESS, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE OR USE BY YOU. WE DO NOT ACCEPT RESPONSIBILITY FOR THE DELETION OR FAILURE TO STORE ANY USER COMMUNICATION, ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE IN THIS REGARD.

               </Typography>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:26,  fontWeight: 900, mt: 6, ml: -76 }}>
               MISCELLANEOUS PROVISIONS
                 
               </Typography>

   <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:19, ml: 10, fontWeight: 400,mt: 1  }}>
   •	We reserve the right to terminate, change, suspend or discontinue any aspect of the Platform at any time and without notice.<br/>
   <br/>•	We reserve the right to amend these Terms of Use at any time and at our sole discretion. We shall give you notice of such amendment by publication of the amended version via the Platform. Your continued access to and use of the Service is your acceptance of and compliance with the updated Terms of Use. If you disagree with any part of hereof, do not access the Platform.<br/>
   <br/>•	You are responsible for your connection to the mobile phone networks, the Internet and all costs associated with these connections.<br/>
   <br/>•	All copyright, trademarks and other intellectual property rights used as part of our services or contained on the Platform belong to us or its licensors. Nothing set forth in this Terms of Use shall constitute a transfer, license or assignment by us to you of any Intellectual Property Rights owned by us or displayed on the Platform<br/>
   <br/>•	If any provision of this Terms of Use is declared unlawful and/or unenforceable by operation of law, any court or authority, such provision be severed from this Terms of Use and rendered ineffective so far as is possible without modifying the remaining provisions of this Agreement.<br/>
   <br/>•	You agree that regardless of any provision of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or the Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred and extinguished.<br/>
   <br/>•	You acknowledge and agree that this Terms of Use be interpreted and enforced in accordance with the laws of the Federal Republic of Nigeria.<br/>
   <br/>•	The Parties shall use their best efforts to amicably settle all disputes arising out of or in connection with the performance or interpretation of this Terms of Use. Any dispute or differences arising out of the construction, interpretation or performance of the obligations created under this relationship which cannot be settled amicably within one (1) month after receipt by a party of the other party's request for such amicable settlement shall be submitted to a Court of competent jurisdiction in the Federal Republic of Nigeria.<br/>

               </Typography>
  
  
   </>
   ) : 
   
   (
    <>
 
             <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:25, fontWeight: 900,textAlign: "center", display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 5  }}>
               Term of use
                 
               </Typography>
               <Box
       
       sx={{
         //m: 'auto',
         width: "100%",
         height: "auto",
         backgroundSize: 'contain',
         mt:1,
         display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
         // backgroundImage: (theme) =>
         //   theme.palette.mode === 'light'
         //     ? items[selectedItemIndex].imageLight
         //     : items[selectedItemIndex].imageDark,
       }}
     >
        <img
         src={
             'https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834'
         }
         style={{ 
             width: '30%',
             height: '30%',
             resizeMode: 'cover',
       cursor: 'pointer',
       height:"auto"
       }}
         alt="apple"
         
       />

</Box>

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 500, mt:3, textAlign: "center",display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center', }}>
              Please read these Terms of Use carefully before using the Almond Platform (“Platform”). Customers who are under 18 years of age should review these terms and conditions with a parent or guardian. 
               Your access to and use of the Platform by way of any Device is conditioned on your acceptance of and compliance with the provisions of this Terms of Use. 
               By your accessing or using the Platform you agree to be bound by these Terms of Use as amended at any time and posted on the website www.almondnigeria.com. 
               If you disagree with any part hereof, do not access the Platform. In addition, your use of the Platform shall be subject to any applicable regulatory guidelines or rules. 
               All such guidelines or rules are hereby incorporated by reference into the Terms of Use.
               </Typography>
              

               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
               DESCRIPTION OF THE ALMOND PLATFORM
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
               The Platform is a financial service application that allows you purchase airtime, pay bills, send money, receive money and use card payment services via the web (www.almondnigeria.com) or mobile app (Almond apps). 
               All transactions performed are final and cannot be recalled except in the case of a proven fraud.
                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                FAILED TRANSACTIONS AND REVERSALS
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
               In the event of a failed transaction (i.e. user has been debited for transaction, but value was not received), please send the following details to <strong>support@almondnigeria.com</strong> for resolution: user’s mobile number, transaction amount, transaction date, transaction type and any other detail we would request to enable us resolve your complaint. We shall use our best endeavors to liaise with the applicable financial institution, telecommunication company and/or merchant to resolve your logged transaction issue.
                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                NOTICES
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
               Your email address and phone number supplied at sign-up will be used as a means of communicating information, notices and documents to you. Any changes to such information should be logged as a ticket on <strong>support@almondnigeria.com</strong>
                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                SECURITY AND UNAUTHORISED USE
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
                <br/> •	You are responsible for all transactions conducted using your computer (personal or otherwise), mobile phone, tablet or other electronic device (“Device”), payment card or account. It is your responsibility to keep all of these secure<br/>
  <br/>•	All passwords must be kept secret. You are liable for all activities undertaken using your username, associated password or Device. You are responsible for maintaining the confidentiality of all Account information and keeping your Device from unauthorized use.<br/>
  <br/>•	We accept no liability for any unauthorized use of your Device, payment card or account or any effects of same.<br/>
  <br/>•	In the event your Device has been stolen or there is an unauthorized access to your payment card or account, it is your responsibility to immediately notify your bank to restrict transactions on your card and account.<br/>
  <br/>•	In the event your Device has been stolen or there is an unauthorized access to your Almond account, it is your responsibility to immediately log as a ticket on <strong>support@almondnigeria.com</strong><br/>
  <br/>•	If you dispute any purchase or withdrawal on your card or account, you will be required to prove that the transaction was effected without your authorization and such transaction will be investigated by our Fraud Team once we receive a logged ticket on <strong>support@almondnigeria.com</strong><br/>

                </Typography>


                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                SUSPENSION OF ACCESS TO PLATFORM
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
         We hereby reserve the right to suspend, restrict, or terminate your access to the Platform (in whole or in part) without any notice or liability in the following circumstances:
   <br/> •	Upon receipt of instructions from you to suspend or terminate your access to the Platform<br/> 
   <br/> •	Upon receipt of notification from you that your Device has been lost or stolen<br/> 
   <br/> •	If we suspect the Platform is being used for fraudulent or other illegal activities.<br/> 
   <br/> •	If we are of the opinion that you have breached a provision contained in this Terms of Use.<br/> 
   <br/> •	If we are of the opinion that continued access to the Platform will be detrimental to our business.<br/> 
   <br/> •	If you provide any information that is untrue, inaccurate, not current or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete.<br/> 

                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                UNACCEPTABLE USE
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
         You shall not and shall not permit any person using your Device or account to:
   <br/>•	Use this Platform in a way that causes or could cause damage or restrict the availability or accessibility of the Platform;<br/>
   <br/>•	Conduct any fraudulent or illegal activities via the Platform;<br/>
   <br/>•	You hereby understand and agree that if a fraudulent activity is associated with the operation of your account, We have the right to apply restrictions to your account and report to the appropriate law enforcement agencies.<br/>


                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                PRIVACY STATEMENT
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
         •	You acknowledge that by your use of the Platform, we shall be privy to and store some of your personal information (full name, mobile phone number and email address and some cases BVN).   <br/>
   <br/>•	We shall use our best endeavors to ensure that your personal information is secure on our systems.<br/>
   <br/>•	We may use your personal information for:
   <br/>o	providing you the service, processing transactions and sending you related information;
   <br/>o	providing, maintaining and improving our services;
   <br/>o	sending support and administrative messages to you;
   <br/>o	responding to your enquiries and requests;
   <br/>o	providing customer service;
   <br/>o	updating you on products, services, promotions, rewards, and events offered by us and third parties; and
   <br/>o	A better understanding of your needs by combining it with information collected from others with the objective of improving on our service delivery.   <br/>
   <br/>•	We may disclose any information we collect about you to third parties as set forth below:
   <br/>o	To regulatory bodies;
   <br/>o	To financial institutions;
   <br/>o	To companies, such as email service providers that perform marketing services on our behalf;
   <br/>o	To third parties to enable us provide Service to you; and
   <br/>o	If required to be disclosed under applicable law, or stock exchange regulation or by a government order, decree, regulation or rule or by a court order.
   <br/>•	You hereby consent to the processing, transfer and storage of your information during and after the use of the Platform.<br/>


                </Typography>
                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                INDEMNITY
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
         •	You agree to indemnify Transatlantic Trusted Partners Limited, and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses due to or arising out of any transaction you process through the Platform.<br/>
   <br/>•	You agree to indemnify Transatlantic Trusted Partners Limited and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses arising from your violation of this Terms of Use<br/>


                </Typography>

                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                LIMITATION OF LIABILITY
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
       •	In no event shall we be liable for:
   <br/>o	unavailability of the Platform at any time or your inability to use the Platform.
   <br/>o	any form of delivery or failure thereof by a financial institution, merchant or telecommunication company listed on the Almond Platform.
   <br/>o	erroneous input of transaction details.
   <br/>o	any loss arising from any failure or delay in any mobile phone networks or internet service provider or defective Device.
   <br/>o	any special, indirect, incidental, punitive, or consequential damages, including loss of profits, however caused.<br/>
•	Under no circumstances shall it be implied that we endorse, sponsor, certify or otherwise guarantee the sale or use of any merchant’s products and/services listed on the Platform. Use and/or purchase of same shall be at your own risk.<br/>
<br/>•	WE SHALL USE OUR BEST ENDEAVOURS TO ENSURE THE PLATFORM FUNCTIONS OPTIMALLY AT ALL TIMES AND OUR SYSTEMS ARE FREE FROM VIRUSES, HOWEVER WE CANNOT ENSURE SUCH EXCLUSION AND NO LIABILITY IS ACCEPTED FOR SAME. HENCE IT IS RECOMMENDED YOU PUT IN PLACE YOUR OWN SECURITY MEASURES. WE DO NOT WARRANT THAT THE OPERATION OF THE PLATFORM WILL BE UNINTERRUPTED NOR ERROR FREE, NOR WILL IT BE 100% FRAUD OR FAIL PROOF.WE ARE PROVIDING THE SERVICE “AS IS” WITHOUT ANY EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THE SERVICES OR ITS CONDITION, TIMELINESS, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE OR USE BY YOU. WE DO NOT ACCEPT RESPONSIBILITY FOR THE DELETION OR FAILURE TO STORE ANY USER COMMUNICATION, ACTIONS, CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE IN THIS REGARD.


                </Typography>


                <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:14,  fontWeight: 900, mt: 3 }}>
                MISCELLANEOUS PROVISIONS
                 
               </Typography>
        
               <Typography component="h2" variant="h4" color="text.primary"   sx={{fontSize:13, fontWeight: 400, 
                    mt: 1
                    }}>
       •	We reserve the right to terminate, change, suspend or discontinue any aspect of the Platform at any time and without notice.<br/>
   <br/>•	We reserve the right to amend these Terms of Use at any time and at our sole discretion. We shall give you notice of such amendment by publication of the amended version via the Platform. Your continued access to and use of the Service is your acceptance of and compliance with the updated Terms of Use. If you disagree with any part of hereof, do not access the Platform.<br/>
   <br/>•	You are responsible for your connection to the mobile phone networks, the Internet and all costs associated with these connections.<br/>
   <br/>•	All copyright, trademarks and other intellectual property rights used as part of our services or contained on the Platform belong to us or its licensors. Nothing set forth in this Terms of Use shall constitute a transfer, license or assignment by us to you of any Intellectual Property Rights owned by us or displayed on the Platform<br/>
   <br/>•	If any provision of this Terms of Use is declared unlawful and/or unenforceable by operation of law, any court or authority, such provision be severed from this Terms of Use and rendered ineffective so far as is possible without modifying the remaining provisions of this Agreement.<br/>
   <br/>•	You agree that regardless of any provision of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or the Terms of Use must be filed within one (1) year after such claim or cause of action arose or be forever barred and extinguished.<br/>
   <br/>•	You acknowledge and agree that this Terms of Use be interpreted and enforced in accordance with the laws of the Federal Republic of Nigeria.<br/>
   <br/>•	The Parties shall use their best efforts to amicably settle all disputes arising out of or in connection with the performance or interpretation of this Terms of Use. Any dispute or differences arising out of the construction, interpretation or performance of the obligations created under this relationship which cannot be settled amicably within one (1) month after receipt by a party of the other party's request for such amicable settlement shall be submitted to a Court of competent jurisdiction in the Federal Republic of Nigeria.<br/>

                </Typography>
 
 
    </>
    )
   }
              
     

           
         
      </Container>
    </Box>
  );
}
