import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import { createTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';

const logoStyle = {
  width: '130px',
  height: 'auto',
  cursor: 'pointer',
  
  
};
const logoStyle2 = {
  width: '90px',
  height: 'auto',
  cursor: 'pointer',
  
  
};

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [buttoncolor, setbuttoncolor] = useState('')
  const [imgshow,setimgshow] = useState('')
  const [textcolor,settextcolor ] = useState('')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100); 
  const [glow,setglow] = useState('')

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100)
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const getAppBarBackgroundColor = () => {
    if (scrollY > 100) {
      setimgshow('https://ik.imagekit.io/zjvk6l5gp/Logo2.svg?updatedAt=1708922149834')
      settextcolor("black")
      setbuttoncolor("white")
      setglow(`4px 4px 12px -2.5px rgba(125, 93, 246, 0.7)`)
      return '#fff';
    }else{
      setimgshow("https://ik.imagekit.io/zjvk6l5gp/Logo1.svg?updatedAt=1708922149813")
      settextcolor("white")
      setbuttoncolor("black")
      setglow(`4px 4px 12px -2.5px rgba(255, 255, 255, 1)`)
      return 'rgba(125, 93, 246, 0.9)'; 
    }
    
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
         mt: 0,
          //top:-60
          
        }}
      >
        {/* <Container maxWidth="" >
     
        </Container> */}
        {!isMobile ? (
           <Toolbar
            variant="regular"
            sx={(theme) => ({
             // display: 'flex',
              alignItems: 'center',
              //justifyContent: 'space-between',
              //flexShrink: 0,
              //borderRadius: '999px',
              // bgcolor:
              //   theme.palette.mode === 'light'
              //     ? 'rgba(255, 255, 255, 0.4)'
              //     : 'rgba(0, 0, 0, 0.4)',
              bgcolor: getAppBarBackgroundColor(),
        transition: 'background-color 0.3s ease-in-out',
            backdropFilter: 'blur(84px)',
              maxHeight: 100,
              height: 100,
             // border: '1px solid',
            //  borderColor: 'divider',
              // boxShadow:
              //   theme.palette.mode === 'light'
              //     ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
              //     : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',

             boxShadow: glow
            })}
            
            
            
          >
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
               ml: '8%',
               mt:-2
              
            
               
                
              }}
              
            >
              <Link  href="/">
              <img
                src={
                  imgshow
                }
                style={logoStyle}
                alt="logo of sitemark"
                href="/"
              />
          </Link>
             
              <Box sx={{ display: { xs: 'none', md: 'flex' },mt:2.5, px: "3%"  }}>
                <MenuItem
                  onClick={() => scrollToSection('features')}
                  // sx={{ py: '6px', px: '23px',  }}
                >
                  <Typography variant="body2" color={textcolor} fontSize={18} fontWeight={"bold"} >
                    Features
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => scrollToSection('testimonials')}
                  sx={{ py: '6px', px: '23px' }}
                >
                  <Typography variant="body2" color={textcolor} fontSize={18} fontWeight={"bold"} >
                    Testimonials
                  </Typography>
                </MenuItem> */}
                <MenuItem
                  onClick={() => scrollToSection('highlights')}
                  sx={{ py: '6px', px: '23px' }}
                >
                <Typography variant="body2" color={textcolor} fontSize={18} fontWeight={"bold"}>
                Why us?
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('pricing')}
                  sx={{ py: '6px', px: '23px' }}
                >
                  <Typography variant="body2" color={textcolor} fontSize={18} fontWeight={"bold"}>
                  Membership plans
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('faq')}
                  sx={{ py: '6px', px: '23px' }}
                >
                  <Typography variant="body2" color={textcolor} fontSize={18} fontWeight={"bold"}>
                    Faq
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                //gap: 0.5,
                alignItems: 'center',
                mr:"7%"
              }}
            >
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} color={textcolor}  /> */}
              
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} color={textcolor}  />
              <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-in/"
                target="_blank"
              >
                Sign in
              </Button>  */}
              
              <Button
                //color="light"
                //variant="outlined"
                //size="large"
                component="a"
                href="https://apps.apple.com/ng/app/almond-app/id6479360310"
                target="_blank"
                sx={{backgroundColor: textcolor, borderRadius: "9px", color: buttoncolor, width: 140, height: 45}}
               
                
              >
                Get Almond
              </Button>
                {/* <Button
                //color="light"
                variant="outlined"
                //size="large"
                component="a"
                href="/material-ui/getting-started/templates/sign-up/"
                target="_blank"
                sx={{backgroundColor: "transparent", borderRadius: "9px", color: textcolor, width: 140, height: 45, borderWidth: 1, borderColor: textcolor}}
               
                
              >
                Get Almond
              </Button> */}
            </Box>
          
          </Toolbar>) : ( 
          <Toolbar
            variant="regular"
            sx={(theme) => ({
             // display: 'flex',
              alignItems: 'center',
              bgcolor: getAppBarBackgroundColor(),
        transition: 'background-color 0.3s ease-in-out',
             // backdropFilter: 'blur(24px)',
              maxHeight: 160,
              height: 70,
              boxShadow: glow
            })}
            
            
            
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: "8%",
              }}
            >
              <Link  href="/">
              <img
                src={
                  imgshow
                }
                style={logoStyle2}
                alt="logo of sitemark"
                href="/"
              />
          </Link>
            
            </Box>
         
            <Box sx={{ }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px',color: textcolor}}
                size="medium"
              >
                <MenuIcon color={textcolor} />
              </Button>
              <SwipeableDrawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60vw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    

                  }}
                >
             
                  <MenuItem  sx={{fontSize: 16, fontWeight: "bold"}} onClick={() => scrollToSection('features')}>
                    Features
                  </MenuItem>
                  {/* <MenuItem  sx={{ fontSize: 16, fontWeight: "bold"}} onClick={() => scrollToSection('testimonials')}>
                    Testimonials
                  </MenuItem> */}
                  <MenuItem  sx={{fontSize: 16, fontWeight: "bold"}} onClick={() => scrollToSection('highlights')}>
                  Why us?
                  </MenuItem>
                  <MenuItem  sx={{fontSize: 16, fontWeight: "bold"}} onClick={() => scrollToSection('pricing')}>
                  Membership plans
                  </MenuItem>
                  <MenuItem  sx={{fontSize: 16, fontWeight: "bold"}} onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                  <Divider />
                  <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="https://apps.apple.com/ng/app/almond-app/id6479360310"
                      target="_blank"
                      sx={{backgroundColor: "black", borderRadius: "9px", color: "white", width: 140, height: 45,}}
                    >
                       Get Almond
                    </Button>
                  
                </Box>
              </SwipeableDrawer>
            </Box>
          </Toolbar>)}
          
         
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
